import React, { useState, useEffect, useRef } from 'react';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import ROUTES from '../../routes';
import {
  Page,
  OperationsTable,
  OperationsTableMoreInformation,
  OperationsTableMoreInformationItem,
  TitleBox,
  BoxSeparator,
  ContentBox,
  FiltersContainer,
  FiltersSeparator,
  FiltersSelector,
  FiltersDateSelector,
  SearchBox,
  ForbiddenSection,
} from '../../components';
import { business as businessActions } from '../../actions';
import { Language } from '../../utils';
import SadIcon from '../../static/images/icons/sad.svg';
import MobileBalanceIcon from '../../static/images/icons/mobile_balance-round.svg';
import makeClasses from './styles';
import { Divider, Grid, Hidden, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../components/FiltersDateSelector';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import { Policies } from '../../utils/Policies';
import { toISOString } from '../../utils/Utils';
import Markdown from 'markdown-to-jsx';

const CHARGE_STATES = {
  PENDING: 'MobileBalance.ChargeStates.PENDING',
  COMPLETED: 'MobileBalance.ChargeStates.COMPLETED',
  FAIL: 'MobileBalance.ChargeStates.FAIL',
  BLOCKED: 'MobileBalance.ChargeStates.BLOCKED',
  STARTED: 'MobileBalance.ChargeStates.STARTED',
};

const RECHARGE_STATES = {
  PENDING: 'MobileBalance.RechargeStates.PENDING',
  ACCREDITED: 'MobileBalance.RechargeStates.ACCREDITED',
  FAIL: 'MobileBalance.RechargeStates.FAIL',
};

const MobileBalanceScreen = ({ history }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [ checkingStatus, setCheckingStatus ] = useState(false);
  const [ mobileBalanceStatus, setMobileBalanceStatus ] = useState(false);
  const [ mobileBalance, setMobileBalance ] = useState(0);
  const [ mobileBalanceActivePos, setMobileBalanceActivePos ] = useState({});
  const [ mobileBalanceHistory, setMobileBalanceHistory ] = useState({});
  const [ paginationPage, setPaginationPage ] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [ paginationPageSize ] = useState(10);
  const [ phoneCompanies, setPhoneCompanies ] = useState([]);
  const [ selectedPhoneCompany, setSelectedPhoneCompany ] = useState(searchParams.pcid ? searchParams.pcid : 'all');
  const [selectedDate, setSelectedDate] = useState(searchParams.fid ? searchParams.fid === 'custom' ? searchParams.fid : parseInt(searchParams.fid) : 3);
  const [ selectedCustomDate, setSelectedCustomFilter ] = useState(
    searchParams.fid === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [ amountSearchParam, setAmountSearchParam ] = useState(
    searchParams.amountSearch ? searchParams.amountSearch : ''
  );
  const [ selectedAmountSearchParam, setSelectedAmountSearchParam ] = useState(
    searchParams.amountSearch ? searchParams.amountSearch : ''
  );
  const [ phoneDigitsSearchParam, setPhoneDigitsSearchParam ] = useState(
    searchParams.phoneDigitsSearch ? searchParams.phoneDigitsSearch : ''
  );
  const [ selectedPhoneDigitsSearchParam, setSelectedPhoneDigitsSearchParam ] = useState(
    searchParams.phoneDigitsSearch ? searchParams.phoneDigitsSearch : ''
  );
  const [ showSearchBars, setShowSearchBars ] = useState(false);
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const prevPaginationPage = useRef(null);
  const i18n = Language(languageTexts);
  const mobileBalanceActive = mobileBalanceStatus === true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => {
      if (!policies || !policies.includes(Policies.types.MOBILE_BALANCE)) {
        setForbbidenSection(true);
      } else if (policies.includes(Policies.types.MOBILE_BALANCE)) {
        dispatch(
          businessActions.getMobileBalanceStatus(status => {
            setMobileBalanceStatus(status);
            setCheckingStatus(false);
          })
        );
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (mobileBalanceStatus) {
        dispatch(
          businessActions.getMobileBalance(balance => {
            setMobileBalance(balance);
          })
        );
        dispatch(
          businessActions.getMobileBalanceActivePos(activePos => {
            setMobileBalanceActivePos(activePos);
          })
        );
        dispatch(
          businessActions.getPhoneCompanies(companies => {
            setPhoneCompanies(companies.map(company => ({ id: company, name: company })));
          })
        );
      }
    },
    [ mobileBalanceStatus ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        if (paginationPage > 1 && prevPaginationPage.current === paginationPage) {
          _handleOnPaginationChange(1);
          prevPaginationPage.current = null;
        } else {
          loadData();
          prevPaginationPage.current = paginationPage;
        }
      }
    },
    [
      forbiddenSection,
      selectedDate,
      selectedCustomDate,
      selectedPhoneCompany,
      selectedAmountSearchParam,
      selectedPhoneDigitsSearchParam,
      mobileBalanceStatus,
      paginationPage,
      paginationPageSize,
    ]
  );

  const loadData = () => {
    if (mobileBalanceStatus) {
      const dateFilter = selectedDate === CUSTOM_FILTER ? selectedCustomDate : getDateRangeForFilter(selectedDate);
      const startDate = toISOString(dateFilter.startDate);
      const endDate = toISOString(dateFilter.endDate);
      const phoneCompany = selectedPhoneCompany !== 'all' ? selectedPhoneCompany : null;

      dispatch(
        businessActions.getMobileBalanceHistory(
          startDate,
          endDate,
          phoneCompany,
          null,
          selectedAmountSearchParam,
          selectedPhoneDigitsSearchParam,
          paginationPage,
          paginationPageSize,
          mobileBalanceHistory => {
            setMobileBalanceHistory(mobileBalanceHistory);
          }
        )
      );
    }
  };

  const _handleOnPaginationChange = nextPage => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnDateFilterSelected = (filter, startDate, endDate) => {
    setSelectedDate(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnPhoneCompanySelected = company => {
    setSelectedPhoneCompany(company);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.pcid = company;

    dispatch(
      replace({
        search: qs.stringify(searchParams),
      })
    );
  };

  const renderDatesFilter = () => (
    <FiltersDateSelector
      selectedFilter={selectedDate}
      startDate={selectedCustomDate.startDate}
      endDate={selectedCustomDate.endDate}
      onFilterSelected={_handleOnDateFilterSelected}
      dateFormat={i18n.get('DateFormat', false)}
    />
  );

  const renderCompaniesFilter = () => (
    <FiltersSelector
      options={phoneCompanies}
      selected={selectedPhoneCompany}
      onSelected={_handleOnPhoneCompanySelected}
      showDefaultOption
      defaultOption={i18n.get('MobileBalance.FiltersCompaniesDefaultOption')}
    />
  );

  const _handleOnAmountSearch = e => {
    setAmountSearchParam(e.target.value);
  };

  const _handleAmountSearchOnChange = e => {
    e.preventDefault();

    setSelectedAmountSearchParam(amountSearchParam);

    const searchParams = qs.parse(history.location.amountSearch) || {};
    searchParams.amountSearch = amountSearchParam || null;

    dispatch(
      replace({
        search: qs.stringify(searchParams, { skipNull: true, skipEmptyString: true }),
      })
    );
  };

  const _handleOnPhoneDigitsSearch = e => {
    setPhoneDigitsSearchParam(e.target.value);
  };

  const _handlePhoneDigitsSearchOnChange = e => {
    e.preventDefault();

    setSelectedPhoneDigitsSearchParam(phoneDigitsSearchParam);

    const searchParams = qs.parse(history.location.phoneDigitsSearch) || {};
    searchParams.phoneDigitsSearch = phoneDigitsSearchParam || null;

    dispatch(
      replace({
        search: qs.stringify(searchParams, { skipNull: true, skipEmptyString: true }),
      })
    );
  };

  const _handleOnSearchToggle = e => {
    e.preventDefault();

    setShowSearchBars(!showSearchBars);
  };

  const renderInlineDetails = details => {
    if (!details) {
      return null;
    }

    return (
      <OperationsTableMoreInformation>
        {details.chargeStatus ? (
          <OperationsTableMoreInformationItem
            label={i18n.get('MobileBalance.BalanceHistoryGridDetails.ChargeStatus')}
            value={details.chargeStatus}
          />
        ) : null}
        {details.chargeAmount ? (
          <OperationsTableMoreInformationItem
            label={i18n.get('MobileBalance.BalanceHistoryGridDetails.ChargeAmount')}
            value={details.chargeAmount}
          />
        ) : null}
      </OperationsTableMoreInformation>
    );
  };

  const renderOperationsTable = () => {
    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('MobileBalance.BalanceHistoryGrid.Column1'),
        sortable: false,
        type: 'date-time',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('MobileBalance.BalanceHistoryGrid.Column2'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get('MobileBalance.BalanceHistoryGrid.Column3'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get('MobileBalance.BalanceHistoryGrid.Column4'),
        sortable: false,
        type: 'text',
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get('MobileBalance.BalanceHistoryGrid.Column5'),
        sortable: false,
        type: 'number-inline',
      },
    ];
    const body = (mobileBalanceHistory.elements || []).map(item => ({
      rowId: item.id,
      columns: [
        {
          columnNumber: 1,
          data: item.transmissionDateTime,
        },
        {
          columnNumber: 2,
          data: item.phoneCompany,
        },
        {
          columnNumber: 3,
          data: item.phone,
        },
        {
          columnNumber: 4,
          data: i18n.get(RECHARGE_STATES[item.rechargeState]),
        },
        {
          columnNumber: 5,
          data: item.rechargeAmount,
        },
      ],
      inlineDetails: {
        chargeAmount: `${i18n.get('MobileBalance.BalanceBoxItemCurrency')} ${item.chargeAmount}`,
        chargeStatus: item.chargeStatus ? i18n.get(CHARGE_STATES[item.chargeStatus]) : null,
      },
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={(mobileBalanceHistory.elements || []).length === paginationPageSize}
        onPaginationChange={_handleOnPaginationChange}
        emptyMessage={i18n.get('MobileBalance.GridEmptyMessage')}
        showDetails={false}
        showInlineDetails
        renderInlineDetails={renderInlineDetails}
      />
    );
  };

  const renderSearchToggle = () => {
    return (
      <Hidden mdUp>
        <IconButton onClick={_handleOnSearchToggle} className={classes.searchBarToggle}>
          {showSearchBars ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
        </IconButton>
      </Hidden>
    );
  };

  const renderSearchBars = () => {
    const searchBars = (
      <div className={classes.searchFiltersContainer}>
        <SearchBox
          placeholder={i18n.get('MobileBalance.SearchAmountPlaceholder')}
          onSearch={_handleAmountSearchOnChange}
          onInputChange={_handleOnAmountSearch}
        />
        <div className={classes.searchFilterSeparator} />
        <SearchBox
          placeholder={i18n.get('MobileBalance.SearchPhoneDigitsPlaceholder')}
          onSearch={_handlePhoneDigitsSearchOnChange}
          onInputChange={_handleOnPhoneDigitsSearch}
        />
      </div>
    );

    if (isSmallScreen) {
      return showSearchBars ? searchBars : null;
    }

    return searchBars;
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('MobileBalance.Title')}
        withActivePage={ROUTES.MOBILE_BALANCE.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('MobileBalance.Title')}
      withActivePage={ROUTES.MOBILE_BALANCE.id}
    >
      <TitleBox title={i18n.get('MobileBalance.BoxTitle')} />
      <BoxSeparator />
      {!checkingStatus ? (
        <React.Fragment>
          {mobileBalanceActive ? (
            <React.Fragment>
              <ContentBox
                title={i18n.get('MobileBalance.BalanceBoxTitle')}
                titleBold
                iconLeft={MobileBalanceIcon}
                accordion
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className={classes.contentBoxItemWrapper}>
                      <div className={classes.contentBoxItemLabel}>{i18n.get('MobileBalance.BalanceBoxItemLabel')}</div>
                      <div className={classes.contentBoxItemValue}>
                        <span>{i18n.get('MobileBalance.BalanceBoxItemCurrency')}</span> {mobileBalance.maxBalance}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </ContentBox>
              <BoxSeparator size="small" />
              <ContentBox
                title={i18n.get('MobileBalance.PosBoxTitle')}
                titleBold
                iconLeft={MobileBalanceIcon}
                accordion
              >
                <Grid container spacing={4}>
                  {(mobileBalanceActivePos.elements || []).map((item, index) => (
                    <React.Fragment key={index}>
                      <Hidden mdUp>
                        {index !== 0 ? <Divider className={classes.contentBoxItemSeparator} /> : null}
                      </Hidden>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.contentBoxItemWrapper}>
                          <div className={classes.contentBoxItemLabel}>
                            {i18n.get('MobileBalance.PosBoxItemLabel')} {item.branchName}
                          </div>
                          <div className={classes.contentBoxItemValue}>
                            {i18n.get('MobileBalance.PosBoxItemValue')} {item.networkIdentifier}
                          </div>
                        </div>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </ContentBox>
              <BoxSeparator size="small" />
              <ContentBox title={i18n.get('MobileBalance.FiltersTitle')} customRightAction={renderSearchToggle}>
                <FiltersContainer showLabel>
                  {renderDatesFilter()}
                  <FiltersSeparator />
                  {renderCompaniesFilter()}
                </FiltersContainer>
                {renderSearchBars()}
              </ContentBox>
              <BoxSeparator size="small" />
              <ContentBox title={i18n.get('MobileBalance.GridTitle')} titleBold>
                {renderOperationsTable()}
              </ContentBox>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ContentBox
                iconRight={SadIcon}
                iconInline
                title={i18n.get('MobileBalance.NotActiveDetailsTitle')}
                titleBold
              >
                <Markdown className={classes.notAvailableContent}>{i18n.get('MobileBalance.NotActiveDetails')}</Markdown>
              </ContentBox>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : null}
    </Page>
  );
};

MobileBalanceScreen.id = 'com.Handy.MobileBalance';

export default MobileBalanceScreen;
