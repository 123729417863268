import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { push } from 'connected-react-router';

import ROUTES from '../../routes';
import { Page, LoanAvailable, TitleBox, BoxSeparator, ContentBox, ForbiddenSection } from '../../components';
import { business as businessActions } from '../../actions';
import { Language, Utils } from '../../utils';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import makeClasses from './styles';

import LoansLogo from '../../static/images/pym.svg';
import LoansSmallIcon from '../../static/images/pym_reduced.svg';
import SadIcon from '../../static/images/icons/sad.svg';
import { Hidden } from '@material-ui/core';
import { Policies } from '../../utils/Policies';

export const PAYMENT_STATUS = {
  NOT_PAID: 'NOT_PAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  FULLY_PAID: 'FULLY_PAID',
};

const LoansScreen = () => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const [forbiddenSection, setForbbidenSection] = useState(false);
  const { policies, languageTexts, loans, noOffer, hasLoanAvailable, loanType } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    loans: Array.isArray(state.business.loans) ? state.business.loans : [],
    noOffer: state.business.loanAvailability.offerState === 'NO_OFFER' || false,
    loanType: state.business.loanAvailability ? state.business.loanAvailability.bannerType : null,
    hasLoanAvailable: state.business.loanAvailability
      ? ['AVAILABLE_OFFER', 'RENEWAL'].includes(state.business.loanAvailability.offerState)
      : false,
  }));
  const i18n = Language(languageTexts);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.LOANS)) {
      dispatch(businessActions.checkLoansAvailability());
      dispatch(businessActions.getLoans());
    } else {
      setForbbidenSection(true);
    }
  }, [policies]);

  const handleOnLoanDetailsClick = (loanIndex) => () => {
    dispatch(push(ROUTES.LOAN_DETAILS.path.replace(':loanId', loanIndex)));
  };

  const renderNoAvailableLoan = () => {
    return (
      <ContentBox iconRight={SadIcon} iconInline title={i18n.get('Loans.NoOfferDetailsTitle')} titleBold>
        <div className={classes.notAvailableContent}>{i18n.get('Loans.NoOfferDetails')}</div>
      </ContentBox>
    );
  };

  const renderLoanDetails = (loan, index) => {
    const actualInstallment = loan.installments.find((installment) => installment.number === loan.actualPeriod);

    return (
      <React.Fragment key={index}>
        {index !== 0 ? <BoxSeparator size="small" /> : null}
        <ContentBox
          title={i18n.get('Loans.BoxItemTitle').replace('{installment}', actualInstallment.number)}
          titleBold
          iconLeft={LoansSmallIcon}
          iconInline
          button
          buttonText={i18n.get('Loans.BoxItemButton')}
          buttonOnClick={handleOnLoanDetailsClick(index)}
        >
          <div className={classes.loansContentWrapper}>
            <div>
              <List className={classes.contentTile}>
                <ListItem className={classes.contentItem}>
                  <ListItemText primary={i18n.get('Loans.DetailsRequestedDate')} className={classes.tileTitle} />
                  <div className={classes.tileDetails}>
                    {moment(loan.requestedDate).format(i18n.get('DateFormat', false) || Utils.DEFAULT_DATE_FORMAT)}
                  </div>
                </ListItem>
                <Hidden xsDown>
                  <Divider />
                </Hidden>
                <ListItem className={classes.contentItem}>
                  <ListItemText primary={i18n.get('Loans.DetailsRequestedPeriods')} className={classes.tileTitle} />
                  <div className={classes.tileDetails}>{loan.requestedPeriods}</div>
                </ListItem>
              </List>
            </div>
            <div>
              <List className={classes.contentTile}>
                <ListItem className={classes.contentItem}>
                  <ListItemText primary={i18n.get('Loans.DetailsRequestedCapital')} className={classes.tileTitle} />
                  <div className={classes.tileDetails}>
                    {loan.currency} {Utils.formatCurrency(loan.requestedCapital)}
                  </div>
                </ListItem>
                <Hidden xsDown>
                  <Divider />
                </Hidden>
                <ListItem className={classes.contentItem}>
                  <ListItemText primary={i18n.get('Loans.DetailsAnualDebtRate')} className={classes.tileTitle} />
                  <div className={classes.tileDetails}>{loan.annualEffectiveRate}%</div>
                </ListItem>
              </List>
            </div>
          </div>
        </ContentBox>
      </React.Fragment>
    );
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('Loans.Title')} withActivePage={ROUTES.LOANS.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('Loans.Title')} withActivePage={ROUTES.LOANS.id}>
      {policies.includes(Policies.types.LOANS_WRITE) && hasLoanAvailable ? (
        <LoanAvailable loanType={loanType} i18n={i18n} />
      ) : null}
      <TitleBox title={i18n.get('Loans.BoxTitle')} iconRight={LoansLogo} />
      <BoxSeparator />
      {loans ? loans.map(renderLoanDetails) : null}
      {(!loans || loans.length === 0) && noOffer ? renderNoAvailableLoan() : null}
    </Page>
  );
};

LoansScreen.id = 'com.Handy.Loans';

export default LoansScreen;
