import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles((theme) => ({
  content: {
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.light,

    '& h2': {
      fontFamily: theme.fontFamily.bold,
      fontSize: theme.fontSize.XL,
      marginBottom: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        fontSize: theme.fontSize.L,
      },
    },
    '& h3': {
      fontFamily: theme.fontFamily.bold,
      fontSize: theme.fontSize.L,
      marginBottom: 0,
    },
    '& strong': {
      fontFamily: theme.fontFamily.bold,
    },
    '& p': {
      marginBottom: theme.spacing(5),
    },
    '& a': {
      color: Colors.rgb.primary,
    },
    '& img': {
      height: 18,
      margin: `0 ${theme.spacing(1)}px`,
    },
  },

  helpParagraph: {
    marginBottom: theme.spacing(5),
    fontSize: theme.fontSize.L,
    color: Colors.rgb.contentText,
    fontFamily: theme.fontFamily.light,

    '& strong': {
      fontFamily: theme.fontFamily.bold,
    },

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  helpContentTitle: {
    fontFamily: theme.fontFamily.bold,
    fontSize: theme.fontSize.XL,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.L,
    },
  },
  helpTitleItalic: {
    fontStyle: 'italic',
  },
  helpTitleBold: {
    fontFamily: theme.fontFamily.bold,
  },
}));

export default makeClasses;
