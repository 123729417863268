import React, { useState, useRef, useEffect } from 'react';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import makeClasses from './styles';
import { QRCode } from 'react-qrcode-logo';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '../../utils';
import handyQrLogo from '../../static/images/handy-qr-logo.svg';
import html2canvas from 'html2canvas';
import { business as businessActions } from '../../actions';
import FiltersSelector from '../FiltersSelector';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import FiltersSeparator from '../FiltersSeparator';
import clsx from 'clsx';
import { jsPDF } from 'jspdf';
import PdfTemplateFull from '../../static/images/qr-pdf-template-full.png';
import PdfTemplate from '../../static/images/qr-pdf-template.png';
import AlertBranchesWithNoPaymentMethod from '../AlertBranchesWithNoPaymentMethod';
import HelpBranchesWithNoPaymentMethod from '../HelpBranchesWithNoPaymentMethod';

const QRModal = ({ title, open, onClose, data, showBranchesSelector = false, showPriceDisclaimer = true }) => {
  const qrCode = useRef(null);
  const classes = makeClasses();
  const dispatch = useDispatch();

  const { branches, languageTexts, loading, branchesWithNoPaymentMethod } = useSelector(({ business, language }) => ({
    languageTexts: language.texts || {},
    branches: business.branches || [],
    loading: business.loading || false,
    branchesWithNoPaymentMethod: business.branchesWithNoPaymentMethod || [],
  }));
  const i18n = Language(languageTexts);

  const [selectedBranch, setSelectedBranch] = useState('');
  const [qrCodeData, setQrCodeData] = useState(data || '');
  const [step, setStep] = useState(showBranchesSelector ? 1 : 2);
  const [currencies] = useState([{ id: 0, label: i18n.get('Currency.UYU') }]);
  const [selectedCurrency] = useState(0);
  const [branchesWithSecrets, setBranchesWithSecrets] = useState([]);
  const [showBranchWithNoPaymentMethodWarning, setShowBranchWithNoPaymentMethodWarning] = useState(false);
  const [showBranchWithNoPaymentMethodHelp, setShowBranchWithNoPaymentMethodHelp] = useState(false);

  const showQRCode = showBranchesSelector ? selectedBranch : true;
  const canDownloadQrCode = (showBranchesSelector && !selectedBranch) || loading;

  useEffect(() => {
    if (showBranchesSelector) {
      dispatch(
        businessActions.getSecrets(true, (secrets) => {
          const _secretsTraceIds = secrets.map((s) => s.branchTraceId);
          const _branchesWithSecrets = branches.filter((b) => _secretsTraceIds.includes(b.traceId));
          setBranchesWithSecrets(_branchesWithSecrets);
          if (_branchesWithSecrets.length > 0) {
            setSelectedBranch(_branchesWithSecrets[0].traceId);
          }
        })
      );
    }
  }, [showBranchesSelector, branches]);

  useEffect(() => {
    if (selectedBranch) {
      if (branchesWithNoPaymentMethod.find((b) => b.branchTraceId === selectedBranch)) {
        console.log('branch with not payment method');
        setShowBranchWithNoPaymentMethodWarning(true);
      } else {
        setQrCodeData(selectedBranch);
        setShowBranchWithNoPaymentMethodWarning(false);
      }
    }
  }, [selectedBranch]);

  const downloadQR = () => {
    const doc = new jsPDF();

    html2canvas(document.querySelector('#react-qrcode-logo')).then((canvas) => {
      doc.addImage(
        PdfTemplateFull,
        'JPEG',
        0,
        0,
        doc.internal.pageSize.width,
        doc.internal.pageSize.height,
        null,
        'PNG'
      );
      doc.addImage(canvas.toDataURL(), 'PNG', 85.2, 71, 37.6, 37.6);
      doc.addPage();
      doc.addImage(PdfTemplate, 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, null, 'NONE');
      doc.addImage(canvas.toDataURL(), 'PNG', 83.6, 113, 42.3, 42.3);
      doc.save('Handy-QR.pdf');
    });
  };

  const handleOnBranchSelected = (branch) => {
    setSelectedBranch(branch);
  };

  const generateQrCode = async () => {
    dispatch(
      businessActions.getBranchSecret(selectedBranch, (branchSecret) => {
        if (branchSecret) {
          setStep(2);
          setQrCodeData(`${process.env.REACT_APP_QR_CODE_SELF_CHECKOUT_URL}?merchant=${branchSecret}`);
        }
      })
    );
  };

  const goToFirstStep = () => {
    setStep(1);
    setQrCodeData('');
  };

  const _toggleHelp = () => {
    setShowBranchWithNoPaymentMethodHelp(!showBranchWithNoPaymentMethodHelp);
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitleWrapper}>
        <div className={clsx(classes.dialogTitle, classes.dialogTitleWithCloseButton)}>
          {title || i18n.get('Components.QRModal.HeaderTitle')}
        </div>
        <div className={classes.dialogTitleClose} onClick={onClose}>
          <CancelOutlinedIcon className={classes.dialogTitleCloseIcon} color="inherit" />
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrapper}>
        {showBranchWithNoPaymentMethodWarning ? (
          <AlertBranchesWithNoPaymentMethod
            title={i18n.get('Components.QRModal.BranchWithNoPaymentMethodModalTitle')}
            showIcon={false}
            onToggleHelp={_toggleHelp}
          />
        ) : null}
        {showBranchesSelector && step === 1 ? (
          <div className={classes.modalBody}>
            <div className={classes.dialogContentTitle}>{i18n.get('Components.QRModal.FiltersTitle')}</div>
            <div className={classes.dialogContentFiltersWrapper}>
              <div>
                <div className={classes.dialogContentFiltersTitle}>
                  {i18n.get('Components.QRModal.BranchesSelectorLabel')}:
                </div>
                <FiltersSelector
                  options={branchesWithSecrets}
                  showDefaultOption={false}
                  selected={selectedBranch}
                  onSelected={handleOnBranchSelected}
                  idField="traceId"
                  nameField="name"
                />
              </div>
              <FiltersSeparator />
              <div>
                <div className={classes.dialogContentFiltersTitle}>
                  {i18n.get('Components.QRModal.CurrenciesSelectorLabel')}:
                </div>
                <FiltersSelector
                  options={currencies}
                  showDefaultOption={false}
                  selected={selectedCurrency}
                  nameField="label"
                  disabled
                />
              </div>
            </div>
          </div>
        ) : null}
        {showQRCode && step === 2 ? (
          <div className={clsx(classes.modalBody, classes.modalBodyCentered)}>
            <div className={classes.dialogContentTitle}>{i18n.get('Components.QRModal.Title')}</div>
            <QRCode
              ref={qrCode}
              value={qrCodeData}
              size={220}
              quietZone={15}
              logoWidth={40}
              logoHeight={41}
              bgColor="#fff"
              logoImage={handyQrLogo}
              removeQrCodeBehindLogo
            />
            {showPriceDisclaimer ? (
              <div className={classes.modalContentFooter}>{i18n.get('Components.QRModal.Note')}</div>
            ) : null}
          </div>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.dialogActionsWrapper}>
        <Button onClick={onClose} variant="outlined">
          {i18n.get('Components.QRModal.ButtonCancel')}
        </Button>
        {step === 1 ? (
          <React.Fragment>
            <Button
              onClick={generateQrCode}
              disabled={canDownloadQrCode}
              loading={loading}
            >
              {i18n.get('Components.QRModal.ButtonNext')}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {showBranchesSelector ? (
              <Button variant="outlined" onClick={goToFirstStep}>
                {i18n.get('Components.QRModal.ButtonBack')}
              </Button>
            ) : null}
            <Button onClick={downloadQR}>{i18n.get('Components.QRModal.ButtonDownload')}</Button>
          </React.Fragment>
        )}
      </DialogActions>
      <HelpBranchesWithNoPaymentMethod open={showBranchWithNoPaymentMethodHelp} onToggleHelp={_toggleHelp} />
    </Dialog>
  );
};

export default QRModal;
