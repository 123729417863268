import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import { Auth, Amplify } from 'aws-amplify';
import { toast } from 'react-toastify';
import qs from 'query-string';
import _ from 'lodash';
import ReactGA from 'react-ga4';

import { user as userActions, userTypes, business as businessActions, common as commonActions } from '../actions';
import { API, Language } from '../utils';
import { API_PATHS, API_RESPONSES } from '../utils/Api';
import {
  postMerchantData,
  postMerchantFile,
  confirmMerchantData,
  postRepresentativeData,
  confirmRepresentativeData,
} from '../api/onboarding-legal-person';
import { sha256 } from 'js-sha256';

Amplify.configure({
  Auth: {
    cookieStorage: {
      domain: process.env.REACT_APP_AWS_AMPLIFY_COOKIES_HOST,
      path: process.env.REACT_APP_AWS_AMPLIFY_COOKIES_PATH,
      secure: process.env.REACT_APP_AWS_AMPLIFY_COOKIES_SECURE === 'YES'
    },
  }
});

const qsConfig = {
  skipNull: true,
  skipEmptyString: true,
};

async function handleError(error, autoClose = 5000) {
  const i18n = Language();
  if (error && error.message) {
    const msj = typeof error.message === 'string' ? error.message : error.message.title || error.message.Message;
    if (typeof msj === 'string' && msj.includes('There is already a user with the username')) {
      toast.error(i18n.get('ValidationUsernameAlreadyExistsError'), {
        autoClose,
      });
    } else if (typeof msj === 'string' && msj.includes('Merchant has reached its user limit')) {
      toast.error(i18n.get('ValidationMaximumUsersReachedError'), {
        autoClose,
      });
    } else if (typeof msj === 'string' && msj.includes('New email address must be different than the current one')) {
      toast.error(i18n.get('ValidationSameEmailError'), { autoClose });
    } else {
      toast.error(
        error.message.code
          ? i18n.get(error.message.code, false) || i18n.get('Error')
          : typeof error.message === 'string'
          ? error.message
          : i18n.get('Error'),
        { autoClose }
      );
    }
  } else {
    toast.error(i18n.get('Error'), { autoClose });
  }
}

function* initialize({ callback = {} }) {
  yield put(userActions.setLoading(true));

  try {
    const session = yield Auth.currentSession();
    const sessionToken = session.getIdToken();
    const authIdToken = sessionToken.getJwtToken();

    if (!authIdToken) {
      throw null;
    }

    const userData = yield select(({ user }) => user.userData);
    const merchantTraceId = JSON.parse(sessionToken.payload.merchantTraceId);
    const profileResult = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).PROFILE.DATA());

    yield put(
      userActions.setUserData({
        ...userData,
        merchantTraceId: merchantTraceId,
        policies: sessionToken.payload.policies ? JSON.parse(sessionToken.payload.policies) : [],
        authTime: sessionToken.payload.auth_time || Date.now(),
        hasRUT: profileResult?.data?.merchantIdentifier.merchantDocumentType === "RUT"
      })
    );
    if (process.env.REACT_APP_ENABLE_SECTION_NOTIFICATIONS === 'YES') {
      yield call(getUnreadNotificationsCount);
    }
    yield put(businessActions.initialize());
    setUserForGa(userData.preferred_username);

    if (typeof callback === 'function') {
      callback();
    }
  } catch (e) {
    handleError({ message: 'ErrorSessionExpired' });
    yield call(logOut, {});
  }

  yield put(userActions.setLoading(false));
}

function* getNotifications({ page, wasRead, hideExpired, isClosed, priority, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        page,
        count: 15,
        wasRead,
        hideExpired,
        isClosed,
        priority: priority?.toUpperCase() || null 
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).NOTIFICATIONS.LIST());
    
    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getNotification({ notificationId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).NOTIFICATIONS.ONE(notificationId));
    
    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback(null)
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* deleteNotification({ notificationId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.delete(API_PATHS().MERCHANTS(merchantTraceId).NOTIFICATIONS.ONE(notificationId));

    if (result.status === API_RESPONSES.SUCCESS) {
      yield call(getUnreadNotificationsCount);
      callback(true);
    } else {
      callback(false);
    }
  } catch (e) {
    callback(false);
  }

  yield put(userActions.setLoading(false));
}

function* markNotificationAsRead({ notificationId }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    yield API.patch(API_PATHS().MERCHANTS(merchantTraceId).NOTIFICATIONS.MARK_AS_READ(notificationId));
    yield call(getUnreadNotificationsCount);
  } catch (e) {
    console.log(e);
  }

  yield put(userActions.setLoading(false));
}

function* getUnreadNotificationsCount() {
  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).NOTIFICATIONS.UNREAD_COUNT());

    if (result.status === 'success') {
      yield put(userActions.setUnreadNotificationsCount(result.data.unreadNotificationsCount));
    } else {
      throw result.message;
    }
  } catch (e) {
    console.log(e)
  }
}

function* closeNotification({ id }) {
  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    yield API.patch(API_PATHS().MERCHANTS(merchantTraceId).NOTIFICATIONS.CLOSE(id));
  } catch (e) {
    console.log(e)
  }
}

function* getToken({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.post(API_PATHS().MERCHANTS(merchantTraceId).PROFILE.GET_TOKEN());
    
    if (result.status === 'success') {
      callback(result.data?.token);
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* login({ rut, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const cognitoUser = yield Auth.signIn(rut);
    callback(cognitoUser);
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* loginAdditional({ rut, username, password, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const cognitoUser = yield Auth.signIn(`${rut}.${username}`, password);
    callback(cognitoUser);
  } catch (e) {
    const i18n = Language();
    if (e && e.message === 'User is disabled.') {
      handleError({ message: i18n.get('Login.UserDisabled') }, 15000);
    } else {
      handleError({ message: { code: 'WrongDataException' } });
    }
  }

  yield put(userActions.setLoading(false));
}

function* verifyAccount({ cognitoUser, verificationCode, callbackSuccess }) {
  yield put(userActions.setLoading(true));

  try {
    const user = yield Auth.sendCustomChallengeAnswer(cognitoUser, verificationCode);
    const session = yield Auth.currentSession();
    const sessionToken = session.getIdToken();
    const authIdToken = sessionToken.getJwtToken();

    if (!authIdToken) {
      throw null;
    }

    const policies = sessionToken.payload.policies ? JSON.parse(sessionToken.payload.policies) : [];
    yield put(
      userActions.setUserData({
        ...user.attributes,
        username: user.attributes['custom:rut'],
        merchantTraceId: JSON.parse(sessionToken.payload.merchantTraceId),
        policies,
        authTime: sessionToken.payload.auth_time || Date.now(),
      })
    );
    yield put(businessActions.initialize());
    setUserForGa(user.attributes.preferred_username);

    callbackSuccess(policies);
  } catch (e) {
    const i18n = Language();
    if (e && e.message === 'User is disabled.') {
      handleError({ message: i18n.get('Login.UserDisabled') }, 15000);
    } else {
      handleError({ message: i18n.get('Login.CodeInvalidCode') });
    }
  }

  yield put(userActions.setLoading(false));
}

function* verifyAccountAdditional({ cognitoUser, callbackSuccess, callbackError }) {
  yield put(userActions.setLoading(true));

  try {
    const session = yield Auth.currentSession();
    const sessionToken = session.getIdToken();
    const authIdToken = sessionToken.getJwtToken();

    if (!authIdToken) {
      throw null;
    }

    const policies = sessionToken.payload.policies ? JSON.parse(sessionToken.payload.policies) : [];
    yield put(
      userActions.setUserData({
        ...cognitoUser.attributes,
        username: cognitoUser.attributes['custom:rut'],
        merchantTraceId: JSON.parse(sessionToken.payload.merchantTraceId),
        policies,
        authTime: sessionToken.payload.auth_time || Date.now(),
      })
    );
    yield put(businessActions.initialize());
    setUserForGa(cognitoUser.attributes.preferred_username);

    callbackSuccess(policies);
  } catch (e) {
    console.log(e);

    callbackError();

    const i18n = Language();
    if (e && e.message === 'User is disabled.') {
      handleError({ message: i18n.get('Login.UserDisabled') }, 15000);
    } else {
      handleError({ message: i18n.get('Login.CodeInvalidCode') });
    }
  }

  yield put(userActions.setLoading(false));
}

function* getProfileData({ filters, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const profileResult = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).PROFILE.DATA());
    
    const search = filters ? qs.stringify(
      {
        ...filters,
      },
      qsConfig
    ) : null;

    const branchesResult = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).PROFILE.BRANCHES());

    if (profileResult.status === 'success' && branchesResult.status === 'success') {
      callback(profileResult.data, branchesResult.data);
    } else {
      throw profileResult.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getUserRoles({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        page: null,
        count: null,
      },
      qsConfig
    );
    const rolesResult = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_USERS.ROLES());

    if (rolesResult.status === 'success') {
      callback(rolesResult.data);
    } else {
      throw rolesResult.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getUsersCount({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.COUNT());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getUsers({ page, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        page,
        count: 15,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_USERS.GET_ALL());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getUser({ userTraceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.GET(userTraceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* createUser({ params, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.post(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.CREATE(), params);

    if (result.status === 'success') {
      callback();
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* updateUser({ userTraceId, params, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.UPDATE(userTraceId), params);

    if (result.status === 'success') {
      callback();
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* updatePassword({ userTraceId, password, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.UPDATE_PASSWORD(userTraceId), {
      password,
    });

    if (result.status === 'success') {
      callback();
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* disableUser({ userTraceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.DISABLE(userTraceId));

    if (result.status === 'success') {
      callback();
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* enableUser({ userTraceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.ENABLE(userTraceId));

    if (result.status === 'success') {
      callback();
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* deleteUser({ userTraceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.delete(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.DELETE(userTraceId));

    if (result.status === 'success') {
      callback();
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* requestChangeEmail({ newEmail, callbackSuccess, callbackError }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.REQUEST_CHANGE_EMAIL(), {
      email: newEmail,
    });

    if (result.status === 'success') {
      callbackSuccess(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callbackError();

    const i18n = Language();
    if (e && e.Message === 'User has an active request challenge and must verify with the code') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorUserHasActiveRequest') }, 15000);
    } else if (e && e.Message === 'A request is already being processed by our system') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorTryAgain') }, 15000);
    } else if (e === 'User must have a recent login to call this endpoint.') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorRecentLoginRequired') }, 15000);
    } else if (e && e.Message === 'User has reached the monthly limit of requests for email changes') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorMonthlyLimitExceeded') }, 15000);
    } else {
      handleError({ message: e });
    }
  }

  yield put(userActions.setLoading(false));
}

function* verifyChangeEmail({ accessCode, requestTraceId, callbackSuccess, callbackError }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.VERIFY_CHANGE_EMAIL(), {
      accessCode,
      requestTraceId,
    });

    if (result.status === 'success') {
      callbackSuccess(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callbackError();

    const i18n = Language();
    if (e && e.Message === 'Access code is invalid') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorAccessCodeInvalid') }, 15000);
    } else {
      handleError({ message: e });
    }
  }

  yield put(userActions.setLoading(false));
}

function* getCategories({ categoryType, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().MERCHANTS().CRM.CATEGORIES(categoryType), {}, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* getCountries({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().MERCHANTS().CRM.COUNTRIES(), {}, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* getDepartmentsByCountry({ countryId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().MERCHANTS().CRM.DEPARTMENTS_BY_COUNTRY(countryId), {}, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* getCitiesByDepartments({ departmentId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().MERCHANTS().CRM.CITIES_BY_DEPARTMENTS(departmentId), {}, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* startAffiliation({ input, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.post(API_PATHS().MERCHANTS().CRM.START_AFFILIATION(), input, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result;
    }
  } catch (e) {
    if (
      e.message.Message === 'There is already an affiliation with the email' ||
      e.message.Message === 'There is a pending affiliation with the email'
    ) {
      e.message.code = 'Register.RegisterErrorEmailAlreadyInUse';
    } else if (
      e.message.Message === 'There is already an affiliation with the phone' ||
      e.message.Message === 'There is a pending affiliation with the phone'
    ) {
      e.message.code = 'Register.RegisterErrorPhoneAlreadyInUse';
    } else if (e.message.Message === 'There is already a pending affiliation') {
      e.message.code = 'Register.RegisterErrorCiAlreadyInUse';
    } else if (e.message.Message === 'Limit of affiliation attempts has been reached') {
      e.message.code = 'Register.RegisterLimitOfAffiliationAttemptsReached';
    } else if (e.message.Message === 'Document has invalid format.') {
      e.message.code = 'Register.RegisterErrorCiNotValid';
    }
    handleError(e);
  }

  yield put(userActions.setLoading(false));
}

function* affiliationRequestEmailVerificationCode({ traceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.put(
      API_PATHS().MERCHANTS().CRM.AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE(traceId),
      {},
      true
    );

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    if (e && e.Message === 'Challenge restarts limit has been reached') {
      callback(false);
    } else {
      handleError(e);
      callback();
    }
  }

  yield put(userActions.setLoading(false));
}

function* affiliationRequestPhoneVerificationCode({ traceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.put(
      API_PATHS().MERCHANTS().CRM.AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE(traceId),
      {},
      true
    );

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    if (e && e.Message === 'Challenge restarts limit has been reached') {
      callback(false);
    } else {
      handleError(e);
      callback();
    }
  }

  yield put(userActions.setLoading(false));
}

function* affiliationVerifyVerificationCode({ traceId, requestTraceId, verificationCode, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.put(
      API_PATHS().MERCHANTS().CRM.AFFILIATION_VERIFY_VERIFICATION_CODE(traceId),
      {
        RequestTraceId: requestTraceId,
        AccessCode: verificationCode,
      },
      true
    );

    callback(result.data);
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* affiliationGetIdentityVerificationLink({ traceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.put(
      API_PATHS().MERCHANTS().CRM.AFFILIATION_GET_IDENTITY_VERIFICATION_LINK(traceId),
      {},
      true
    );

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* affiliationGetIdentityVerificationStatus({ traceId, callback }) {
  try {
    const result = yield API.get(
      API_PATHS().MERCHANTS().CRM.AFFILIATION_GET_IDENTITY_VERIFICATION_STATUS(traceId),
      {},
      true
    );

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }
}

function* logOut() {
  yield put(userActions.setLoading(true));

  try {
    yield Auth.signOut();
  } catch (e) {
    handleError();
  } finally {
    yield put(userActions.clear());
    yield put(businessActions.clear());
    yield put(commonActions.clear());
    yield put(userActions.userLoggedOut());

    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = '/';
  }

  yield put(userActions.setLoading(false));
}

function* getPaymentMethods({ selectedView, page, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const statuses =
      selectedView === 0 ? null : selectedView === 1 ? 'REJECTED' : selectedView === 2 ? 'PENDING' : null;
    const search = qs.stringify(
      {
        statuses,
        page,
        count: 15,
      },
      qsConfig
    );
    const result = yield API.get(
      API_PATHS(search)
        .MERCHANTS(merchantTraceId)
        .MANAGE_PAYMENT_METHODS.GET_ALL(statuses !== null)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      const branchesSearch = qs.stringify(
        {
          page: 1,
          count: 10,
        },
        qsConfig
      );
      const branchesByPaymentMethod = yield all(
        result.data.elements.map((paymentMethod) =>
          API.get(
            API_PATHS(branchesSearch)
              .MERCHANTS(merchantTraceId)
              .MANAGE_PAYMENT_METHODS.GET_BRANCHES_BY_PAYMENT_METHOD(paymentMethod.traceId)
          )
        )
      );

      branchesByPaymentMethod.forEach((branchesByPaymentMethod, index) => {
        result.data.elements[index].branches = branchesByPaymentMethod.data.elements;
      });

      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getPaymentEntities({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_PAYMENT_METHODS.GET_PAYMENT_ENTITIES());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getPaymentEntityRegex({ entityId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_PAYMENT_METHODS.GET_PAYMENT_ENTITY_REGEX(entityId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getPaymentCurrencies({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_PAYMENT_METHODS.GET_PAYMENT_CURRENCIES()
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* addPaymentMethod({ data, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.post(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_PAYMENT_METHODS.ADD_PAYMENT_METHOD(),
      data
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    const i18n = Language();
    if (e && e.Message === 'User has an active request challenge and must verify with the code') {
      handleError(
        {
          message: i18n.get('ManagePaymentMethodsAdd.ChangePaymentMethodErrorUserHasActiveRequest'),
        },
        15000
      );
    } else if (e && e.Message === 'User has reached the monthly limit of requests for payment_method changes') {
      handleError(
        {
          message: i18n.get('ManagePaymentMethodsAdd.ChangePaymentMethodErrorMonthlyLimitExceeded'),
        },
        15000
      );
    } else if (e && e.Message === 'A request is already being processed by our system') {
      handleError(
        {
          message: i18n.get('ManagePaymentMethodsAdd.ChangePaymentMethodErrorRequestInProccess'),
        },
        15000
      );
    } else if (e && e.Message === 'User must have a recent login to call this endpoint.') {
      handleError(
        {
          message: i18n.get('ManagePaymentMethodsAdd.ChangePaymentMethodrrorRecentLoginRequired'),
        },
        15000
      );
    } else {
      handleError({ message: e });
    }
  }

  yield put(userActions.setLoading(false));
}

function* verifyChangePaymentMethod({ accessCode, requestTraceId, callbackSuccess, callbackError }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_PAYMENT_METHODS.VERIFY_CHANGE_PAYMENT_METHOD(),
      {
        accessCode,
        requestTraceId,
      }
    );

    if (result.status === 'success') {
      callbackSuccess(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callbackError();
    const i18n = Language();
    if (e && e.Message === 'Access code is invalid') {
      handleError(
        {
          message: i18n.get('ManagePaymentMethodsAdd.ChangePaymentMethodErrorAccessCodeInvalid'),
        },
        15000
      );
    } else {
      handleError({ message: e });
    }
  }

  yield put(userActions.setLoading(false));
}

function* assignBranchesToPaymentMethod({ paymentMethodTraceId, branchesToAssign, branchesToRemove, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const assignPromieses = branchesToAssign.map((branchTraceId) =>
      API.put(
        API_PATHS()
          .MERCHANTS(merchantTraceId)
          .MANAGE_PAYMENT_METHODS.ASSIGN_BRANCHES_TO_PAYMENT_METHOD(branchTraceId, paymentMethodTraceId)
      )
    );
    const unassignPromises = branchesToRemove.map((branchTraceId) =>
      API.delete(
        API_PATHS()
          .MERCHANTS(merchantTraceId)
          .MANAGE_PAYMENT_METHODS.ASSIGN_BRANCHES_TO_PAYMENT_METHOD(branchTraceId, paymentMethodTraceId)
      )
    );
    const results = yield all([...assignPromieses, ...unassignPromises]);

    if (_.every(results, { status: 'success' })) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (e) {
    callback(false);
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getPaymentMethodsByBranches({ branchTraceIds, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const results = yield all([
      branchTraceIds.map((branchTraceId) =>
        API.get(
          API_PATHS().MERCHANTS(merchantTraceId).MANAGE_PAYMENT_METHODS.GET_PAYMENT_METHODS_BY_BRANCH(branchTraceId)
        )
      ),
    ]);

    if (_.every(results[0], { status: 'success' })) {
      callback(results[0].map((result) => result.data.elements));
    } else {
      callback([]);
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getPaymentMethodFullAccountNumber({ paymentMethodTraceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS()
        .MERCHANTS(merchantTraceId)
        .MANAGE_PAYMENT_METHODS.GET_PAYMENT_METHOD_FULL_ACCOUNT_NUMBER(paymentMethodTraceId)
    );

    if (result.status === 'success') {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(userActions.setLoading(false));
}

function* merchantStartAffiliation({ input, callback }) {
  yield put(userActions.setLoading(true));
  try {
    const result = yield API.post(API_PATHS().MERCHANTS().CRM.MERCHANT_START_AFFILIATION(), input, true);
    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result;
    }
  } catch (e) {
    if (
      e.message.Message === 'There is already an affiliation with the email' ||
      e.message.Message === 'There is a pending affiliation with the email'
    ) {
      e.message.code = 'Register.RegisterErrorEmailAlreadyInUse';
    } else if (
      e.message.Message === 'There is already an affiliation with the phone' ||
      e.message.Message === 'There is a pending affiliation with the phone'
    ) {
      e.message.code = 'Register.RegisterErrorPhoneAlreadyInUse';
    } else if (e.message.Message === 'There is already a pending affiliation') {
      e.message.code = 'Register.RegisterErrorCiAlreadyInUse';
    }
    handleError(e);
  }

  yield put(userActions.setLoading(false));
}

function* merchantAffiliationRequestEmailVerificationCode({ traceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.put(
      API_PATHS().MERCHANTS().CRM.MERCHANT_AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE(traceId),
      {},
      true
    );

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    if (e && e.Message === 'Challenge restarts limit has been reached') {
      callback(false);
    } else {
      handleError(e);
      callback();
    }
  }

  yield put(userActions.setLoading(false));
}

function* merchantAffiliationRequestPhoneVerificationCode({ traceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.put(
      API_PATHS().MERCHANTS().CRM.MERCHANT_AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE(traceId),
      {},
      true
    );

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    if (e && e.Message === 'Challenge restarts limit has been reached') {
      callback(false);
    } else {
      handleError(e);
      callback();
    }
  }

  yield put(userActions.setLoading(false));
}

function* merchantAffiliationVerifyVerificationCode({ traceId, requestTraceId, verificationCode, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.put(
      API_PATHS().MERCHANTS().CRM.MERCHANT_AFFILIATION_VERIFY_VERIFICATION_CODE(traceId),
      {
        RequestTraceId: requestTraceId,
        AccessCode: verificationCode,
      },
      true
    );

    callback(result.data);
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* getMccs({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().MERCHANTS().CRM.MCCS(), {}, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* getPaymentPlans({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().MERCHANTS().CRM.PAYMENT_PLANS(), {}, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* merchantAffiliationData({ traceId, merchantData, dgiFiles, exemptionFiles, callback }) {
  yield put(userActions.setLoading(true));

  try {
    yield call(postMerchantData, {
      traceId: traceId,
      merchantData: merchantData,
    });

    yield all(
      dgiFiles.map((file) =>
        call(postMerchantFile, {
          traceId: traceId,
          type: 'DGI_FORM',
          file: file,
        })
      )
    );

    yield all(
      exemptionFiles.map((file) =>
        call(postMerchantFile, {
          traceId: traceId,
          type: 'EXEMPT_FROM_WITHHOLDINGS',
          file: file,
        })
      )
    );

    yield call(confirmMerchantData, traceId);

    yield call(callback, 'ok');
  } catch (e) {
    yield call(callback, 'error');
  }

  yield put(userActions.setLoading(false));
}

function* getPositions({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().MERCHANTS().CRM.POSITIONS(), {}, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* getBusinessNameTypes({ callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().MERCHANTS().CRM.BUSSINES_NAME_TYPES(), {}, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([]);
  }

  yield put(userActions.setLoading(false));
}

function* merchantRepresentativeData({ traceId, params, pepFiles, callback }) {
  yield put(userActions.setLoading(true));

  try {
    yield call(postRepresentativeData, {
      traceId: traceId,
      representativeData: params,
    });

    yield all(
      pepFiles.map((file) =>
        call(postMerchantFile, {
          traceId: traceId,
          type: 'POLITICALLY_EXPOSED_PERSON',
          file: file,
        })
      )
    );

    yield call(confirmRepresentativeData, traceId);

    yield call(callback, 'ok');
  } catch (e) {
    yield call(callback, 'error');
  }

  yield put(userActions.setLoading(false));
}

function* merchantAffiliationIdentityVerification({ traceId, callback }) {
  yield put(userActions.setLoading(true));
  try {
    const result = yield API.put(API_PATHS().MERCHANTS().CRM.MERCHANT_IDENTITY_VERIFICATION(traceId), {}, true);
    callback(result.data);
  } catch (e) {
    callback([]);
  }
  yield put(userActions.setLoading(false));
}

function* merchantAffiliationValidateEmail({ traceId, input, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.put(API_PATHS().MERCHANTS().CRM.MERCHANT_AFFILIATION_VALIDATE_EMAIL(traceId), input, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    if (e && e.Message === 'Invalid email format') {
      callback(false);
    } else {
      handleError(e);
      callback();
    }
  }

  yield put(userActions.setLoading(false));
}

function* merchantAffiliationValidatePhone({ traceId, input, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const result = yield API.put(API_PATHS().MERCHANTS().CRM.MERCHANT_AFFILIATION_VALIDATE_PHONE(traceId), input, true);

    if (result.status === 'success') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    if (e && e.Message === 'Invalid phone format') {
      callback(false);
    } else {
      handleError(e);
      callback();
    }
  }

  yield put(userActions.setLoading(false));
}

function* getPos({ page, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        page,
        count: 15,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_POS_REQUESTS.GET_POS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getPosRequests({ page, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        page,
        states: ['CANCELLED', 'REJECTED', 'PENDING_ASSIGNMENT', 'PENDING_APPROVAL', 'PENDING_CHARGE'],
        count: 15,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_POS_REQUESTS.GET_REQUESTS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getPosRequestsHistory({ page, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        page,
        states: ['ASSIGNED', 'CANCELLED', 'REJECTED'],
        count: 15,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_POS_REQUESTS.GET_ALL_PENDING());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getPosTypes({ personType, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const search = qs.stringify(
      {
        personType,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS().MANAGE_POS_REQUESTS.GET_TYPES());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* addPosRequest({ params, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.post(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_POS_REQUESTS.ADD_REQUEST(params.branchTraceId),
      {
        PhysicalPOSTypeId: params.physicalPOSTypeId,
        AddressId: params.addressId,
      }
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* cancelPosRequest({ posTraceId, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.delete(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_POS_REQUESTS.CANCEL_REQUEST(posTraceId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(userActions.setLoading(false));
}

function* getPosByBranches({ branchTraceIds, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        page: 1,
        count: 50,
      },
      qsConfig
    );

    const results = yield all([
      branchTraceIds.map((branchTraceId) =>
        API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_POS_REQUESTS.GET_POS_BY_BRANCH(branchTraceId))
      ),
    ]);

    if (_.every(results[0], { status: 'success' })) {
      callback(results[0].map((result) => result.data.elements));
    } else {
      callback([]);
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* getHandyTaps({ page, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        page,
        count: 15,
        merchantTraceId
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_HANDY_TAPS.GET_ALL());
    
    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callback([])
    handleError({ message: e });
  }

  yield put(userActions.setLoading(false));
}

function* addHandyTaps({ branchId, params, callback }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.post(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_HANDY_TAPS.ADD_HANDY_TAP(branchId),
      {
        ...params,
        merchantTraceId
      }
    );
    
    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      throw result.message;
    }
  } catch (e) {
    const i18n = Language();
    if (e && e.Message === 'The maximum number (0) of devices per branch was reached.') {
      handleError({ message: i18n.get('AddHandyTap.AddHandyTapMaxDevicesError') }, 15000);
    } else {
      handleError({ message: e });
    }
  }

  yield put(userActions.setLoading(false));
}

function* verifyDataChange({ accessCode, requestTraceId, callbackSuccess, callbackError }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.VERIFY_DATA_CHANGE(), {
      accessCode,
      requestTraceId,
    });

    if (result.status === 'success') {
      callbackSuccess(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callbackError();

    const i18n = Language();
    if (e && e.Message === 'Access code is invalid') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorAccessCodeInvalid') }, 15000);
    } else {
      handleError({ message: e });
    }
  }

  yield put(userActions.setLoading(false));
}

function* requestChangePhoneNumber({ countryId, dialingCode, newPhoneNumber, callbackSuccess, callbackError }) {
  yield put(userActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_USERS.REQUEST_CHANGE_PHONE_NUMBER(), {
      countryId,
      dialingCode,
      number: newPhoneNumber,
    });

    if (result.status === 'success') {
      callbackSuccess(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    callbackError();

    const i18n = Language();
    if (e && e.Message === 'User has an active request challenge and must verify with the code') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorUserHasActiveRequest') }, 15000);
    } else if (e && e.Message === 'A request is already being processed by our system') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorTryAgain') }, 15000);
    } else if (e === 'User must have a recent login to call this endpoint.') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorRecentLoginRequired') }, 15000);
    } else if (e && e.Message === 'User has reached the monthly limit of requests for email changes') {
      handleError({ message: i18n.get('Profile.ChangeEmailErrorMonthlyLimitExceeded') }, 15000);
    } else {
      handleError({ message: e });
    }
  }

  yield put(userActions.setLoading(false));
}

function setUserForGa(uId) {
  const encoder = new TextEncoder();
  const data = encoder.encode(uId);
  const buff = sha256.create();
  buff.update(data);
  const bArray = Array.from(new Uint8Array(buff.arrayBuffer()));
  const id = bArray.map(b => b.toString(16).padStart(2, '0')).join('');
  ReactGA.set({ userId: id });
}

export default function* UserSagas() {
  yield all([
    takeEvery(userTypes.INITIALIZE, initialize),
    takeEvery(userTypes.LOGIN, login),
    takeEvery(userTypes.LOGIN_ADDITIONAL, loginAdditional),
    takeEvery(userTypes.LOGOUT, logOut),
    takeEvery(userTypes.VERIFY_ACCOUNT, verifyAccount),
    takeEvery(userTypes.VERIFY_ACCOUNT_ADDITIONAL, verifyAccountAdditional),
    takeEvery(userTypes.GET_PROFILE_DATA, getProfileData),
    takeEvery(userTypes.GET_USER_ROLES, getUserRoles),
    takeEvery(userTypes.GET_USERS, getUsers),
    takeEvery(userTypes.GET_USER, getUser),
    takeEvery(userTypes.CREATE_USER, createUser),
    takeEvery(userTypes.UPDATE_USER, updateUser),
    takeEvery(userTypes.UPDATE_PASSWORD, updatePassword),
    takeEvery(userTypes.DISABLE_USER, disableUser),
    takeEvery(userTypes.ENABLE_USER, enableUser),
    takeEvery(userTypes.DELETE_USER, deleteUser),
    takeEvery(userTypes.GET_USERS_COUNT, getUsersCount),
    takeEvery(userTypes.REQUEST_CHANGE_EMAIL, requestChangeEmail),
    takeEvery(userTypes.GET_CATEGORIES, getCategories),
    takeEvery(userTypes.GET_COUNTRIES, getCountries),
    takeEvery(userTypes.GET_DEPARTMENTS_BY_COUNTRY, getDepartmentsByCountry),
    takeEvery(userTypes.GET_CITIES_BY_DEPARTMENTS, getCitiesByDepartments),
    takeEvery(userTypes.START_AFFILIATION, startAffiliation),
    takeEvery(userTypes.AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE, affiliationRequestEmailVerificationCode),
    takeEvery(userTypes.AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE, affiliationRequestPhoneVerificationCode),
    takeEvery(userTypes.AFFILIATION_VERIFY_VERIFICATION_CODE, affiliationVerifyVerificationCode),
    takeEvery(userTypes.AFFILIATION_GET_IDENTITY_VERIFICATION_LINK, affiliationGetIdentityVerificationLink),
    takeEvery(userTypes.AFFILIATION_GET_IDENTITY_VERIFICATION_STATUS, affiliationGetIdentityVerificationStatus),
    takeEvery(userTypes.GET_PAYMENT_METHODS, getPaymentMethods),
    takeEvery(userTypes.GET_PAYMENT_METHODS_BY_BRANCHES, getPaymentMethodsByBranches),
    takeEvery(userTypes.GET_PAYMENT_METHOD_FULL_ACCOUNT_NUMBER, getPaymentMethodFullAccountNumber),
    takeEvery(userTypes.GET_PAYMENT_ENTITIES, getPaymentEntities),
    takeEvery(userTypes.GET_PAYMENT_CURRENCIES, getPaymentCurrencies),
    takeEvery(userTypes.GET_PAYMENT_ENTITY_REGEX, getPaymentEntityRegex),
    takeEvery(userTypes.ADD_PAYMENT_METHOD, addPaymentMethod),
    takeEvery(userTypes.VERIFY_CHANGE_PAYMENT_METHOD, verifyChangePaymentMethod),
    takeEvery(userTypes.ASSIGN_BRANCHES_TO_PAYMENT_METHOD, assignBranchesToPaymentMethod),
    takeEvery(userTypes.BUSINESS_NAME_TYPES, getBusinessNameTypes),
    takeEvery(userTypes.MERCHANT_START_AFFILIATION, merchantStartAffiliation),
    takeEvery(
      userTypes.MERCHANT_AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE,
      merchantAffiliationRequestEmailVerificationCode
    ),
    takeEvery(
      userTypes.MERCHANT_AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE,
      merchantAffiliationRequestPhoneVerificationCode
    ),
    takeEvery(userTypes.MERCHANT_AFFILIATION_VERIFY_VERIFICATION_CODE, merchantAffiliationVerifyVerificationCode),
    takeEvery(userTypes.GET_MCCS, getMccs),
    takeEvery(userTypes.GET_PAYMENT_PLANS, getPaymentPlans),
    takeEvery(userTypes.MERCHANT_AFFILIATION_DATA, merchantAffiliationData),
    takeEvery(userTypes.GET_POSITIONS, getPositions),
    takeEvery(userTypes.MERCHANT_REPRESENTATIVE_DATA, merchantRepresentativeData),
    takeEvery(userTypes.MERCHANT_IDENTITY_VERIFICATION, merchantAffiliationIdentityVerification),
    takeEvery(userTypes.MERCHANT_AFFILIATION_VALIDATE_EMAIL, merchantAffiliationValidateEmail),
    takeEvery(userTypes.MERCHANT_AFFILIATION_VALIDATE_PHONE, merchantAffiliationValidatePhone),
    takeEvery(userTypes.GET_POS, getPos),
    takeEvery(userTypes.GET_POS_REQUESTS, getPosRequests),
    takeEvery(userTypes.GET_POS_REQUESTS_HISTORY, getPosRequestsHistory),
    takeEvery(userTypes.GET_POS_TYPES, getPosTypes),
    takeEvery(userTypes.ADD_POS_REQUEST, addPosRequest),
    takeEvery(userTypes.CANCEL_POS_REQUEST, cancelPosRequest),
    takeEvery(userTypes.GET_POS_BY_BRANCHES, getPosByBranches),
    takeEvery(userTypes.GET_HANDY_TAPS, getHandyTaps),
    takeEvery(userTypes.ADD_HANDY_TAP, addHandyTaps),
    takeEvery(userTypes.REQUEST_CHANGE_PHONE_NUMBER, requestChangePhoneNumber),
    takeEvery(userTypes.VERIFY_DATA_CHANGE, verifyDataChange),
    takeEvery(userTypes.GET_TOKEN, getToken),
    takeEvery(userTypes.GET_NOTIFICATIONS, getNotifications),
    takeEvery(userTypes.GET_NOTIFICATION, getNotification),
    takeEvery(userTypes.DELETE_NOTIFICATION, deleteNotification),
    takeEvery(userTypes.MARK_NOTIFICATION_AS_READ, markNotificationAsRead),
    takeEvery(userTypes.GET_UNREAD_NOTIFICATIONS_COUNT, getUnreadNotificationsCount),
    takeEvery(userTypes.CLOSE_NOTIFICATIONS, closeNotification),
  ]);
}
