import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { toast } from 'react-toastify';

import ROUTES from '../../../../../routes';
import { BoxSeparator, ContentBox, Page, TitleBox, Button, ForbiddenSection, Box } from '../../../../../components';
import { business as businessActions } from '../../../../../actions';
import { Language } from '../../../../../utils';

import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Publish as UploadIcon } from '@material-ui/icons';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import makeClasses from './styles';
import { Policies } from '../../../../../utils/Policies';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import qs from 'query-string';
import clsx from 'clsx';

const ProvidersSubscriptionsAddTermsAndConditionsScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const uploadInputRef = useRef(null);

  const branchTraceId = match.params && match.params.traceId ? match.params.traceId : null;
  const isEditMode = branchTraceId !== null;

  const [inputBranch, setInputBranch] = useState(null);
  const [inputTerms, setInputTerms] = useState(null);
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [branches, setBranches] = useState([]);
  const [inputTyc, setInputTyc] = useState(false);

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {}
  }));
  const i18n = Language(languageTexts);

  const canSubmitForm = inputTerms && inputBranch;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (policies && policies.includes(Policies.types.PROVIDERS_SUBSCRIPTIONS_PLANS_WRITE)) {
      dispatch(businessActions.getProvidersSubscriptionsMerchant((data) => {
        setBranches(data.branches?.filter(b => b.serviceStatus === 'ACTIVE')?.map(b => ({ traceId: b.branchId, name: b.name })) || []);
        if (isEditMode || searchParams.branchTraceId) {
          setInputBranch(isEditMode ? branchTraceId : searchParams.branchTraceId);
        }
      }))
    } else {
      setForbbidenSection(true);
    }
  }, [policies, branchTraceId, isEditMode]);

  useEffect(() => {
    const checkTyC = async () => {
      if (inputTyc === true) {
        const response = await fetch('/handy_tc.pdf');
        const blob = await response.blob();
        const file = new File([blob], 'handy_tc.pdf', { type: 'application/pdf' });

        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        uploadInputRef.current.files = dataTransfer.files;
        setInputTerms(dataTransfer.files);
      } else {
        uploadInputRef.current.files = null;
        setInputTerms(null);
      }
    };

    checkTyC();
  }, [inputTyc]);

  const _goBack = (e) => {
    e.preventDefault();

    if (searchParams?.redirectTo) {
      dispatch(push(searchParams?.redirectTo));
    } else {
      dispatch(goBack());
    }
  };

  const _submitForm = () => {
    if (!canSubmitForm) {
      return false;
    }

    dispatch(
      businessActions.providersSubscriptionsAddTermsAndConditions(inputBranch, inputTerms[0], (result) => {
        if (result) {
          toast.success(i18n.get('ProvidersSubscriptionsAddTermsAndConditions.SuccessAddMessage'));
          setTimeout(() => dispatch(push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS.path)), 1000);
        }
      })
    );
  };

  const _getOptionLabel = (array) => (option) => {
    let foundOption = null;
    if (array === 'branches') {
      foundOption = branches.find((c) => c.traceId === option);
      return foundOption?.name || foundOption?.internalName || '';
    }

    return '';
  };

  const _handleOnChangeBranch = (e, newValue) => {
    setInputBranch(newValue);
  };

  const handleOnFileUpload = (e) => {
    setInputTerms(e.currentTarget.files);
  };

  const renderMainForm = () => {
    return (
      <Grid container justify="center" alignItems="center" className={classes.formWrapper}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <form layout="vertical" onSubmit={_submitForm} className={classes.form}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                  options={branches.map((c) => c.traceId)}
                  getOptionLabel={_getOptionLabel('branches')}
                  onChange={_handleOnChangeBranch}
                  classes={{ inputRoot: classes.formInputSelect }}
                  value={inputBranch}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.formInput}
                      classes={{ root: classes.formInput }}
                      label={i18n.get('ProvidersSubscriptionsAddTermsAndConditions.BranchInputLabel')}
                      placeholder={i18n.get('ProvidersSubscriptionsAddTermsAndConditions.BranchInputPlaceholder')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.inputFile}>
                  <input ref={uploadInputRef} id="file" type="file" accept="application/pdf" onChange={handleOnFileUpload} />
                  <label htmlFor="file">
                    <div>{i18n.get('ProvidersSubscriptionsAddTermsAndConditions.TermsInputLabel')}</div>
                    {!inputTerms
                      ? isEditMode
                        ? i18n.get('ProvidersSubscriptionsAddTermsAndConditions.TermsInputPlaceholder')
                        : i18n.get('ProvidersSubscriptionsAddTermsAndConditions.TermsInputNoTyCPlaceholder')
                      : inputTerms?.[0]?.name}
                    <div className={classes.inputFileIcon}>
                      <UploadIcon fontSize="small" />
                    </div>
                  </label>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Alert
                  severity="info"
                  icon={false}
                  className={clsx(
                    classes.alert,
                    classes.alertInfo,
                    classes.tycAlert
                  )}
                >
                  <div>
                    <InfoOutlinedIcon
                      className={clsx(
                        classes.alertIcon,
                        classes.tycAlertIcon
                      )}
                    />
                  </div>
                  <div
                    className={clsx(
                      classes.alertMessage,
                      classes.tycAlertMessage
                    )}
                  >
                    <AlertTitle
                      className={clsx(
                        classes.alertTitle,
                        classes.tycAlertTitle
                      )}
                    >
                      {i18n.get("ProvidersSubscriptionsAddTermsAndConditions.TycTemplateAlertTitle")}
                    </AlertTitle>
                      <div>{i18n.get("ProvidersSubscriptionsAddTermsAndConditions.TycTemplateAlertMessageLine1")}</div>
                      <div>
                        <span>{i18n.get("ProvidersSubscriptionsAddTermsAndConditions.TycTemplateAlertMessageLine2")}</span>
                        <a className={classes.tycAlertLink} href="/handy_tc.pdf" target="_blank">{i18n.get("ProvidersSubscriptionsAddTermsAndConditions.TycTemplateAlertMessageLine2Link")}</a>
                      </div>
                      <div>
                        <span>{i18n.get("ProvidersSubscriptionsAddTermsAndConditions.TycTemplateAlertMessageLine3")}</span>
                        <FormControlLabel
                          className={classes.tycAlertCheckbox}
                          control={
                            <Checkbox
                              checked={inputTyc}
                              onChange={() => setInputTyc(!inputTyc)}
                            />
                          }
                        />
                      </div>
                  </div>
                </Alert>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersSubscriptionsAddTermsAndConditions.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersSubscriptionsAddTermsAndConditions.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id}
    >
      <TitleBox
        title={
          isEditMode
            ? i18n.get('ProvidersSubscriptionsAddTermsAndConditions.BoxTitleEdit')
            : i18n.get('ProvidersSubscriptionsAddTermsAndConditions.BoxTitleNew')
        }
        back
        backTitle={i18n.get('ProvidersSubscriptionsAddTermsAndConditions.BoxBackButton')}
        onBackClick={_goBack}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      <Box direction="column">
        <ContentBox
          title={i18n.get('ProvidersSubscriptionsAddTermsAndConditions.MainFormTitle')}
          titleBold
          padding={false}
          showShadow={false}
        >
          {renderMainForm()}
        </ContentBox>
        <div className={classes.formSeparator} />
        <div>
          <div className={classes.contentActionsWrapper}>
            <Button variant="outlined" onClick={_goBack}>
              {i18n.get('ProvidersSubscriptionsAddTermsAndConditions.CancelButton')}
            </Button>
            <div className={classes.contentActionSeparator} />
            <Button disabled={!canSubmitForm} onClick={_submitForm}>
              {isEditMode
                ? i18n.get('ProvidersSubscriptionsAddTermsAndConditions.SaveButton')
                : i18n.get('ProvidersSubscriptionsAddTermsAndConditions.CreateButton')}
            </Button>
          </div>
        </div>
      </Box>
    </Page>
  );
};

ProvidersSubscriptionsAddTermsAndConditionsScreen.id = 'com.Handy.ProvidersSubscriptionsAddTermsAndConditions';

export default ProvidersSubscriptionsAddTermsAndConditionsScreen;
