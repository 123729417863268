import React from 'react';
import { useSelector } from 'react-redux';
import { Language } from '../../../utils';
import { Button } from '../../../components';
import makeClasses from '../styles';
import clsx from 'clsx';
import { Alert } from '@material-ui/lab';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import Markdown from 'markdown-to-jsx';

const Disclaimer = ({ nextStep }) => {
  const classes = makeClasses();

  const { languageTexts } = useSelector((state) => ({
    languageTexts: state.language.texts || {},
  }));

  const i18n = Language(languageTexts);

  const handleNext = () => {
    nextStep();
  };

  return (
    <React.Fragment>
      <div className={classes.formContent}>
        <div className={classes.formTitleContainer}>
          <div className={classes.formTitle}>{i18n.get('LegalEntityRegistration.StepFormTitle')}</div>
          <div className={classes.formText}>{i18n.get('LegalEntityRegistration.DisclaimerStep')}</div>
        </div>
        <Alert severity="warning" icon={false} className={clsx(classes.alert, classes.disclaimerAlert)}>
          <div className={classes.alertIconWrapper}>
            <FlagOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <Markdown className={classes.alertBodyText}>
              {i18n.get('LegalEntityRegistration.OnboardingWarningModal.BodyText')}
            </Markdown>
          </div>
        </Alert>
      </div>
      <div className={clsx(classes.termsWrapper, classes.disclaimerTermsWrapper)}>
        <div
          className={clsx(
            classes.formSubmitContainer,
            classes.formSubmitContainerWithOneMiniButton,
            classes.disclaimerFormSubmitContainer
          )}
        >
          <Button className={classes.formSubmitButton} onClick={handleNext}>
            {i18n.get('LegalEntityRegistration.NextButton')}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
Disclaimer.id = 'com.Handy.LegalEntityRegistration.Disclaimer';
export default Disclaimer;
