import { makeStyles } from '@material-ui/core';
import { Colors } from '../../utils';

const makeClasses = makeStyles(theme => ({
  dialog: {
    boxShadow: 'none',
    width: 800,
    maxWidth: 'unset',

    [theme.breakpoints.down('sm')]: {
      width: '95%',
      margin: 0,
    },
  },
  dialogTitleWrapper: {
    background: Colors.rgb.black_light,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,

    '& > h2': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
  },
  dialogTitle: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.XL,
    color: Colors.rgb.white,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: theme.fontSize.M,
    },
  },
  dialogContentWrapper: {
    padding: theme.spacing(4),
  },
  dialogActionsWrapper: {
    padding: `${theme.spacing(2)} 0`,
    margin: `0 ${theme.spacing(4)}`,
    borderTop: `1px solid ${Colors.rgb.contentLineSoft}`,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',
    background: Colors.rgb.alertWarningBackground,
    border: Colors.rgb.alertWarningBorder,
    color: Colors.rgb.alertWarningText,

    '& > div': {
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  alertMessage: {
    marginBottom: 7,
  },
  alertIconWrapper: {
    border: `3px solid ${Colors.rgb.alertWarningText}`,
    borderRadius: 50,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  alertIcon: {
    fontSize: 35,
  },
  alertBodyText: {
    fontFamily: theme.fontFamily.light,

    '& strong': {
      fontFamily: theme.fontFamily.bold,
    },

    '& h2': {
      fontFamily: theme.fontFamily.bold,
      marginTop: 0
    }
  },
}));

export default makeClasses;
