import React, { createRef, useState, useRef, useEffect } from 'react';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Language, Utils } from '../../../utils';
import { user as userActions } from '../../../actions';
import { Button } from '../../../components';
import { Alert, AlertTitle } from '@material-ui/lab';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import makeClasses from '../styles';

import clsx from 'clsx';
import { Input } from '@material-ui/core';

const TIMER_TIME = 60 * 5;

const StepThree = ({ history, nextStep, prevStep, handleChange, stepOneData }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);

  const { loading, languageTexts } = useSelector((state) => ({
    loading: state.user.loading,
    languageTexts: state.language.texts || {},
  }));

  const i18n = Language(languageTexts);

  const [attemptLimitReachedError, setAttemptLimitReachedError] = useState(false);
  const verificationCodeRefs = useRef(new Array(6).fill('').map(() => createRef()));
  const [verificationCode, setVerificationCode] = useState(new Array(6).fill(''));
  const [formattedTime, setFormattedTime] = useState('00:00');
  const [step3Completed, setStep3Completed] = useState(false);
  const [timerStartTime, setTimerStartTime] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [intervalTime, setIntervalTime] = useState(0);

  const canValidateVerificationCode = verificationCode.filter((code) => /[0-9]/.test(code)).length;
  const [affiliationStepThreeData, setAffiliationStepThreeData] = useState(searchParams.affiliationStepTwoData || null);

  const _handleVerificationCodeOnBlur = (index) => (e) => {
    if (e.target.value.length > 0 && !/[0-9]/.test(e.target.value)) {
      const newVerificationCode = verificationCode.slice();
      newVerificationCode[index] = '';
      setVerificationCode(newVerificationCode);
      verificationCodeRefs.current[index].current.focus();
    }
  };

  const _handleVerificationCodeOnChange = (index) => (e) => {
    if (e.target.value.length === 0) {
      const newVerificationCode = verificationCode.slice();
      newVerificationCode[index] = '';
      return setVerificationCode(newVerificationCode);
    }

    if (!/[0-9]/.test(e.target.value)) {
      return;
    }

    const newVerificationCode = verificationCode.slice();
    newVerificationCode[index] = e.target.value.substring(0, 1);
    setVerificationCode(newVerificationCode);

    if (index + 1 < 6) {
      verificationCodeRefs.current[index + 1].current.focus();
    } else if (index === 5) {
      setVerificationCode(newVerificationCode);
    }
  };

  const _handleVerificationCondeOnPaste = (e) => {
    const pastedText = e.clipboardData.getData('text').trim();

    if (!/[0-9]{6}/.test(pastedText)) {
      return alert(i18n.get('Register.CodeInvalidCode'));
    }

    const newVerificationCode = verificationCode.slice();
    for (let i = 0, l = 6; i < l; i += 1) {
      newVerificationCode[i] = pastedText[i];
    }

    setVerificationCode(newVerificationCode);
  };

  const _requestNewVerificationCode = () => {};

  const _startTimer = () => {
    setTimerStartTime(Date.now());
  };

  const _stopTimer = () => {
    setTimerStartTime(null);
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIntervalId(null);
  };

  const _validateVerificationCode = () => {
    const hasValidVerificationCode = verificationCode.filter((code) => /[0-9]/.test(code)).length === 6;

    if (canValidateVerificationCode && hasValidVerificationCode) {
      dispatch(
        userActions.merchantAffiliationVerifyVerificationCode(
          stepOneData,
          affiliationStepThreeData,
          verificationCode.join(''),
          (result) => {
            if (result.result === 'SUCCESSFUL') {
              _stopTimer();
              setStep3Completed(true);
            } else if (result.result === 'WRONG_ACCESS_CODE') {
              toast.error(i18n.get('Register.RegisterCodeValidationError'), { autoClose: 5000 });
            } else if (result.result === 'ATTEMPT_LIMIT_REACHED') {
              setAttemptLimitReachedError(true);
            } else if (result.result === 'EXPIRED_ACCESS_CODE') {
              _stopTimer();
              toast.error(i18n.get('Register.RegisterExpiredValidationCodeError'), { autoClose: 5000 });
            }
          }
        )
      );
    }
  };

  useEffect(() => _validateVerificationCode(), [canValidateVerificationCode]);

  useEffect(() => {
    if (timerStartTime) {
      const timer = TIMER_TIME - moment().diff(moment(timerStartTime), 's');
      if (timer < 0) {
        _stopTimer();
      } else {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        setFormattedTime(`${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`);
      }
    }
  }, [intervalTime, timerStartTime]);

  useEffect(() => {
    if (!intervalId) {
      const id = setInterval(() => {
        setIntervalTime((currentIntervalTime) => currentIntervalTime + 1);
      }, 1000);
      setIntervalId(id);
    }
  }, [intervalId]);

  useEffect(() => {
    _startTimer();
    if (stepOneData) {
      _stopTimer();
      setVerificationCode(new Array(6).fill(''));
      verificationCodeRefs.current[0].current.focus();
      dispatch(
        userActions.merchantAffiliationRequestPhoneVerificationCode(stepOneData, (response) => {
          _startTimer();
          if (response === false) {
            setAttemptLimitReachedError(true);
          } else if (typeof response === 'string') {
            setAffiliationStepThreeData(response);
          }
        })
      );
    }
  }, []);

  const handleNext = () => {
    nextStep({ data: stepOneData });
  };

  return (
    <React.Fragment>
      <div className={classes.formContent}>
        <div className={clsx(classes.formTitleContainer, classes.formTitleContainerWithAlert)}>
          <div className={classes.formTitle}>{i18n.get('LegalEntityRegistration.StepFormTitle')}</div>
          {!attemptLimitReachedError ? (
            <div className={classes.formText}>{i18n.get('LegalEntityRegistration.Step3FormText')}</div>
          ) : null}
        </div>
        {attemptLimitReachedError ? (
          <React.Fragment>
            <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertError)}>
              <div className={classes.alertMessage}>
                <AlertTitle className={classes.alertTitle}>
                  {i18n.get('LegalEntityRegistration.AlertAttemptLimitReachedErrorTitle')}
                </AlertTitle>
                {i18n.get('LegalEntityRegistration.AlertAttemptLimitReachedErrorMessage')}
              </div>
            </Alert>
          </React.Fragment>
        ) : step3Completed ? (
          <React.Fragment>
            <Alert severity="success" icon={false} className={clsx(classes.alert, classes.alertSuccess)}>
              <div>
                <CheckCircleOutlineOutlinedIcon className={classes.alertIcon} />
              </div>
              <div className={classes.alertMessage}>
                <AlertTitle className={classes.alertTitle}>
                  {i18n.get('LegalEntityRegistration.AlertSuccessStep3Title')}
                </AlertTitle>
                {i18n.get('LegalEntityRegistration.AlertSuccessStep3Message')}
              </div>
            </Alert>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Alert severity="warning" icon={false} className={clsx(classes.alert, classes.alertWarning)}>
              <div>
                <TimerOutlinedIcon className={classes.alertIcon} />
              </div>
              <div className={classes.alertMessage}>
                <AlertTitle className={classes.alertTitle}>
                  {i18n.get('LegalEntityRegistration.AlertWarningStep3Title')}
                </AlertTitle>
                {i18n.get('LegalEntityRegistration.AlertWarningStep3Message1')}
                <span className={classes.alertTimer}>{formattedTime}</span>
                <span>{i18n.get('LegalEntityRegistration.AlertWarningStep3Message2')}</span>
              </div>
            </Alert>
            <form layout="vertical" className={clsx(classes.form, classes.formVerificationCode)}>
              <Grid container spacing={1}>
                {verificationCode.map((code, index) => (
                  <Grid key={index} item xs={2} sm={2} md={2}>
                    <Input
                      value={code}
                      onBlur={_handleVerificationCodeOnBlur(index)}
                      onChange={_handleVerificationCodeOnChange(index)}
                      inputProps={{
                        className: classes.verificationCodeInput,
                        inputMode: 'numeric',
                      }}
                      inputRef={verificationCodeRefs.current[index]}
                      onPaste={_handleVerificationCondeOnPaste}
                      disableUnderline={true}
                    />
                  </Grid>
                ))}
              </Grid>
            </form>
          </React.Fragment>
        )}
      </div>
      {!attemptLimitReachedError ? (
        <div
          className={clsx(
            classes.formSubmitContainer,
            step3Completed ? classes.formSubmitContainerWithOneButton : null
          )}
        >
          {!step3Completed ? (
            <React.Fragment>
              <Button
                className={classes.formSubmitButton}
                onClick={_requestNewVerificationCode}
                disabled={timerStartTime}
              >
                {i18n.get('LegalEntityRegistration.RequestNewVerificationCodeButton')}
              </Button>
              <div className={classes.formSubmitButtonSeparator} />
            </React.Fragment>
          ) : null}
          <Button className={classes.formSubmitButton} onClick={handleNext} disabled={loading || !step3Completed}>
            {i18n.get('LegalEntityRegistration.NextButton')}
          </Button>
        </div>
      ) : null}
    </React.Fragment>
  );
};
StepThree.id = 'com.Handy.LegalEntityRegistration.StepThree';
export default StepThree;
