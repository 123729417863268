import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../utils';

const makeClasses = makeStyles((theme) => ({
  boxContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  boxTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${Colors.rgb.contentLine}`,

    '& p': {
      marginTop: 0,
      marginBottom: 0
    },
  },
  boxTitlePriority: {
    '& svg': {
      fill: Colors.rgb.red_dark,
      marginLeft: theme.spacing(1),
    }
  },
  notificationContainer: {
  },
  notificationDateWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: Colors.rgb.contentTextSoft,
    fontSize: theme.fontSize.S,

    '& svg': {
      fill: Colors.rgb.contentText,
      marginLeft: theme.spacing(2),
      marginTop: 2,
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.8
      }
    }
  },
  notificationMessageContainer: {
    fontFamily: theme.fontFamily.light,
    fontSize: theme.fontSize.M,

    '& p': {
      wordBreak: 'break-word',
    },
  },
  alert: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    width: '100%',
    boxSizing: 'border-box',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)} !important`,
      '& > div': {
        alignItems: 'flex-start',
      },
    },
  },
  alertTitle: {
    fontFamily: theme.fontFamily.bold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.M,
    },
  },
  alertMessage: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.fontSize.S,
    },
  },
  alertIcon: {
    fontSize: 50,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  alertDanger: {
    background: Colors.rgb.alertDangerBackground,
    border: `1px solid ${Colors.rgb.alertDangerBorder}`,
    color: `${Colors.rgb.alertDangerText} !important`,
  },
  notificationPageTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& *': {
      fontSize: theme.fontSize.M,
      fontFamily: theme.fontFamily.bold,
      color: Colors.rgb.white,
      margin: 0,
      padding: 0
    }
  },
  notificationTitle: {
    '& *': {
      fontSize: theme.fontSize.M,
      fontFamily: theme.fontFamily.bold,
      color: Colors.rgb.contentTextHighlighted,

      '&:not(ul):not(ol)': {
        margin: 0,
        padding: 0
      }
    }
  },
}));

export default makeClasses;