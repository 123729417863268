import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { push } from 'connected-react-router';
import moment from 'moment';
import ROUTES from '../../routes';
import {
  Box,
  BoxSeparator,
  ContentBox,
  Page,
  SearchBox,
  TitleBox,
  Confirm,
  EmptyMessage,
  FiltersToggle,
  FiltersSeparator,
  FiltersContainer,
  ForbiddenSection,
  Button,
} from '../../components';
import { business as businessActions } from '../../actions';
import { Language, Utils } from '../../utils';
import HandyLink1 from '../../static/images/icons/icon_handylink-1.png';
import HandyLink2 from '../../static/images/icons/icon_handylink-2.png';
import HandyLink3 from '../../static/images/icons/icon_handylink-3.png';
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import {
  Share as ShareIcon,
  Delete as DeleteIcon,
  FileCopy as FileCopyIcon,
  Visibility as VisisbilityIcon,
  Add as AddIcon,
  SaveAlt as SaveAltIcon,
} from '@material-ui/icons';
import SadIcon from '../../static/images/icons/sad.svg';
import MoreVerticalIcon from '../../static/images/icons/more_vertical.svg';
import makeClasses from './styles';
import Pagination from '@material-ui/lab/Pagination';
import { Policies } from '../../utils/Policies';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';

const statusFilterOptions = [
  { id: 0, label: 'PaymentLink.LinkStatusFilter.Status_OK' },
  { id: 2, label: 'PaymentLink.LinkStatusFilter.Status_EXPIRED' },
  { id: 3, label: 'PaymentLink.LinkStatusFilter.Status_USED' },
  { id: 4, label: 'PaymentLink.LinkStatusFilter.Status_BLOCKED' },
];

function PaymentLinkScreen({ history }) {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [ loading, setLoading ] = useState(true);
  const [ menuAnchorEl, setMenuAnchorEl ] = useState(null);
  const [ menuOpenIndex, setMenuOpenIndex ] = useState(-1);
  const [ deleteDialogOpen, setDeleteDialogOpen ] = useState(false);
  const [ handyLinkEnabled, setHandyLinkEnabled ] = useState(false);
  const [ paginationPage, setPaginationPage ] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [ selectedStatus, setSelectedStatus ] = useState(searchParams.status ? parseInt(searchParams.status) : -1);
  const [ searchParam, setSearchParam ] = useState(searchParams.search ? searchParams.search.toLowerCase() : '');
  const [ selectedSearchParam, setSelectedSearchParam ] = useState(
    searchParams.search ? searchParams.search.toLowerCase() : ''
  );
  const [ forbiddenSection, setForbbidenSection ] = useState(false);

  const { policies, paymentLinks, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    paymentLinks: state.business.paymentLinks || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const canAddLink = policies && policies.includes(Policies.types.HANDY_LINK_WRITE);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(
    () => {
      if (policies && policies.includes(Policies.types.HANDY_LINK)) {
        if (loading) {
          dispatch(
            businessActions.getSecrets(false, handyLinkEnabled => {
              setHandyLinkEnabled(handyLinkEnabled);
              if (handyLinkEnabled) {
                dispatch(
                  businessActions.getPaymentLinks(
                    selectedStatus > -1 ? selectedStatus : null,
                    paginationPage,
                    selectedSearchParam
                  )
                );
              }
              setLoading(false);
            })
          );
        } else {
          dispatch(
            businessActions.getPaymentLinks(
              selectedStatus > -1 ? selectedStatus : null,
              paginationPage,
              selectedSearchParam
            )
          );
        }
      } else {
        setLoading(false);
        setForbbidenSection(true);
      }
    },
    [ policies, selectedStatus, paginationPage, selectedSearchParam ]
  );

  const _handleOnSearch = e => {
    e.preventDefault();

    setSelectedSearchParam(searchParam);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.search = searchParam || null;

    dispatch(
      push({
        search: qs.stringify(searchParams, { skipNull: true, skipEmptyString: true }),
      })
    );
  };

  const _handleSearchBoxOnChange = e => {
    setSearchParam(e.target.value);
  };

  const _goToAddPaymentLink = () => {
    dispatch(push(ROUTES.PAYMENT_LINK_NEW.path));
  };

  const _handleMenuOnOpen = index => e => {
    setMenuAnchorEl(e.target);
    setMenuOpenIndex(index);
  };

  const _handleMenuOnClose = () => {
    setMenuAnchorEl(null);
    setMenuOpenIndex(-1);
  };

  const _handleOnShare = traceId => () => {
    _handleMenuOnClose();
    dispatch(push(ROUTES.PAYMENT_LINK_SHARE.path.replace(`:traceId`, traceId)));
  };

  const _handleOnView = traceId => () => {
    _handleMenuOnClose();
    dispatch(push(ROUTES.PAYMENT_LINK_VIEW.path.replace(`:traceId`, traceId)));
  };

  const _handleOnClone = traceId => () => {
    dispatch(push(ROUTES.PAYMENT_LINK_MODIFY.path.replace(`:traceId`, traceId).replace(':modificator', 'clone')));
  };

  const _handleOnDelete = traceId => () => {
    _handleToggleDeleteDialog();
    _handleMenuOnClose();
    dispatch(
      businessActions.deletePaymentLink(traceId, () => {
        dispatch(businessActions.getPaymentLinks(selectedStatus > -1 ? selectedStatus : null, paginationPage));
      })
    );
  };

  const _handleToggleDeleteDialog = () => setDeleteDialogOpen(!deleteDialogOpen);

  const _handleOnStatusChange = status => {
    setSelectedStatus(status);
    setPaginationPage(1);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.status = status;
    searchParams.page = 1;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnPaginationChange = (e, nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnExport = () => {
    dispatch(
      businessActions.exportPaymentLinks(selectedStatus > -1 ? selectedStatus : null, selectedSearchParam, file => {
        Utils.downloadFile(file, 'HandyLinks_' + moment().format(i18n.get('ExportDateFormat')) + '.xlsx');
      })
    );
  };

  const renderSearch = () =>
    handyLinkEnabled ? (
      <div className={classes.searchWrapper}>
        <SearchBox
          placeholder={i18n.get('PaymentLink.SearchBoxPlaceholder')}
          onSearch={_handleOnSearch}
          onInputChange={_handleSearchBoxOnChange}
          initialValue={selectedSearchParam}
          withMargin={false}
        />
      </div>
    ) : null;

  const renderStatusFilter = () =>
    handyLinkEnabled ? (
      <FiltersToggle
        label={i18n.get('PaymentLink.LinkStatusFilterLabel')}
        showLabel
        selected={selectedStatus}
        options={statusFilterOptions}
        onChange={_handleOnStatusChange}
      />
    ) : null;

  const renderDeleteConfirmationDialog = () => {
    const link = menuOpenIndex !== -1 ? paymentLinks.links[menuOpenIndex] : null;

    if (!link) {
      return null;
    }

    return (
      <Confirm
        open={deleteDialogOpen}
        onClose={_handleToggleDeleteDialog}
        title={i18n.get('PaymentLink.DeleteDialogTitle')}
        text={i18n.get('PaymentLink.DeleteDialogContentText')}
        confirmText={i18n.get('PaymentLink.DeleteDialogActionOk')}
        onConfirm={_handleOnDelete(link.traceId)}
        cancelText={i18n.get('PaymentLink.DeleteDialogActionCancel')}
        onCancel={_handleToggleDeleteDialog}
      />
    );
  };

  const renderPaymentLinksList = () => {
    const links = paymentLinks.links || [];

    if (links.length === 0) {
      return (
        <React.Fragment>
          <BoxSeparator size="small" />
          <EmptyMessage>{i18n.get('PaymentLink.SearchEmpty')}</EmptyMessage>
        </React.Fragment>
      );
    }

    return links.map((link, index) => {
      const linkStatus = i18n.get(`PaymentLink.ListItemStatus_${link.status}`) || null;
      return (
        <React.Fragment key={index}>
          <BoxSeparator size="small" />
          <Box align="center" contentOnly right={!isSmallScreen}>
            <div className={classes.listItemImageWrapper}>
              {link.bundleImageUrl ? (
                <img src={link.bundleImageUrl} className={classes.listItemImage} alt="Payment Link" />
              ) : (
                <span>L</span>
              )}
            </div>
            <div className={classes.listItemContent}>
              <div className={classes.listItemContentName}>
                <div>{link.productName}</div>
                {linkStatus ? (
                  <React.Fragment>
                    <div className={classes.listItemContentNameSeparator}>|</div>
                    <div className={clsx(classes.listItemStatus, classes[`listItemStatus_${link.status}`])}>
                      {linkStatus}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
              <div className={classes.listItemContentTotal}>
                <span>
                  {link.currency.alpha} {link.amount}
                </span>
                <span> | </span>
                <span>
                  {i18n.get('PaymentLink.ListItemDate')} {moment(link.creationDate).format('DD/MM/YYYY')}
                </span>
              </div>
            </div>
            <div className={classes.listItemActions}>
              <IconButton onClick={_handleMenuOnOpen(index)}>
                <img src={MoreVerticalIcon} alt="More" />
              </IconButton>
            </div>
            <Menu
              anchorEl={menuAnchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={menuOpenIndex === index}
              onClose={_handleMenuOnClose}
              className={classes.listItemMenu}
            >
              {canAddLink ? (
                <MenuItem onClick={_handleOnShare(link.traceId)} className={classes.listItemMenuItem}>
                  <ListItemIcon>
                    <ShareIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={i18n.get('PaymentLink.ListShareItem')} />
                </MenuItem>
              ) : null}
              <MenuItem onClick={_handleOnView(link.traceId)} className={classes.listItemMenuItem}>
                <ListItemIcon>
                  <VisisbilityIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={i18n.get('PaymentLink.ListViewItem')} />
              </MenuItem>
              {canAddLink ? (
                <MenuItem onClick={_handleOnClone(link.traceId)} className={classes.listItemMenuItem}>
                  <ListItemIcon>
                    <FileCopyIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={i18n.get('PaymentLink.ListCloneItem')} />
                </MenuItem>
              ) : null}
              {canAddLink ? (
                <MenuItem onClick={_handleToggleDeleteDialog} className={classes.listItemMenuItem}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={i18n.get('PaymentLink.ListDeleteItem')} />
                </MenuItem>
              ) : null}
            </Menu>
          </Box>
        </React.Fragment>
      );
    });
  };

  const renderExportButton = () => {
    if (policies && policies.includes(Policies.types.SUPER_READ)) {
      return (
        <Button onClick={_handleOnExport} rightIcon={() => <SaveAltIcon fontSize="small" />}>
          {i18n.get('PaymentLink.ExportButton')}
        </Button>
      );
    }
    return null;
  };

  const renderPaymentLink = () => {
    if (loading) {
      return null;
    }

    if (handyLinkEnabled) {
      return (
        <React.Fragment>
          <TitleBox
            title={i18n.get('PaymentLink.BoxTitle')}
            buttonsRight={
              canAddLink ? (
                [
                  {
                    text: isSmallScreen ? null : i18n.get('PaymentLink.CreateNewPaymentLinkButton'),
                    icon: () => <AddIcon fontSize="small" />,
                    onClick: _goToAddPaymentLink,
                  },
                ]
              ) : null
            }
          />
          <BoxSeparator />
          <ContentBox title={i18n.get('PaymentLink.BoxSearchTitle')} titleBold customRightAction={renderExportButton}>
            <FiltersContainer>{renderStatusFilter()}</FiltersContainer>
            <FiltersSeparator horizontal />
            {renderSearch()}
          </ContentBox>
          {renderPaymentLinksList()}
          {renderDeleteConfirmationDialog()}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <TitleBox
          title={i18n.get('PaymentLink.BoxTitle')}
          buttonRight={handyLinkEnabled}
          buttonRightText={i18n.get('PaymentLink.CreateNewPaymentLinkButton')}
          buttonRightIcon={() => <AddIcon fontSize="small" />}
          buttonRightOnClick={_goToAddPaymentLink}
        />
        <BoxSeparator />
        <div className={!handyLinkEnabled ? classes.infoItemDisabled : null}>
          <Box>
            <div className={classes.infoItem}>
              <Grid container spacing={7}>
                <Grid item xs={12} sm={4} md={4} className={classes.infoItemWrapper}>
                  <img
                    src={HandyLink1}
                    alt={i18n.get('PaymentLink.InfoItem1Title')}
                    className={classes.infoItemImage}
                  />
                  <div className={classes.infoItemTitle}>{i18n.get('PaymentLink.InfoItem1Title')}</div>
                  <div className={classes.infoItemText}>{i18n.get('PaymentLink.InfoItem1Text')}</div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.infoItemWrapper}>
                  <img
                    src={HandyLink2}
                    alt={i18n.get('PaymentLink.InfoItem2Title')}
                    className={classes.infoItemImage}
                  />
                  <div className={classes.infoItemTitle}>{i18n.get('PaymentLink.InfoItem2Title')}</div>
                  <div className={classes.infoItemText}>{i18n.get('PaymentLink.InfoItem2Text')}</div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.infoItemWrapper}>
                  <img
                    src={HandyLink3}
                    alt={i18n.get('PaymentLink.InfoItem3Title')}
                    className={classes.infoItemImage}
                  />
                  <div className={classes.infoItemTitle}>{i18n.get('PaymentLink.InfoItem3Title')}</div>
                  <div className={classes.infoItemText}>{i18n.get('PaymentLink.InfoItem3Text')}</div>
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>
        <BoxSeparator />
        {handyLinkEnabled ? (
          <Box>
            <Markdown className={classes.legendItemWithBackgroundText}>{i18n.get('PaymentLink.BoxHelpText')}</Markdown>
          </Box>
        ) : (
          <ContentBox title={i18n.get('PaymentLink.BoxHelpTitle')} titleBold iconRight={SadIcon} iconInline>
            <Markdown className={classes.legendItemWithBackgroundText}>{i18n.get('PaymentLink.BoxHelpText')}</Markdown>
          </ContentBox>
        )}
        <BoxSeparator />
      </React.Fragment>
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('PaymentLink.Title')}
        withActivePage={ROUTES.PAYMENT_LINK.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('PaymentLink.Title')}
      withActivePage={ROUTES.PAYMENT_LINK.id}
    >
      {renderPaymentLink()}
      {!loading && paymentLinks && paymentLinks.hasNext ? (
        <Pagination
          classes={{
            root: classes.pagination,
            ul: classes.paginationList,
          }}
          count={paymentLinks.count}
          variant="outlined"
          shape="rounded"
          page={paginationPage}
          onChange={_handleOnPaginationChange}
        />
      ) : null}
    </Page>
  );
}

PaymentLinkScreen.id = 'com.Handy.PaymentLink';

export default PaymentLinkScreen;
