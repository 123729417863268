import React from 'react';
import HelpDrawer from '../HelpDrawer';

import makeStyles from './styles';
import { useSelector } from 'react-redux';
import { Language } from '../../utils';
import moreImage from '../../static/images/icons/more_vertical.svg';
import Markdown from 'markdown-to-jsx';

const HelpBranchesWithNoPaymentMethod = ({ open, onToggleHelp }) => {
  const classes = makeStyles();

  const { languageTexts } = useSelector(({ language }) => ({
    languageTexts: language.texts || {},
  }));
  const i18n = Language(languageTexts);

  return (
    <HelpDrawer open={open} onClose={onToggleHelp}>
      <Markdown className={classes.content}>
        {i18n.get('Components.HelpBranchesWithNoPaymentMethod.HelpContent').replace('{image}', moreImage)}
      </Markdown>
    </HelpDrawer>
  );
};

export default HelpBranchesWithNoPaymentMethod;
