import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { business as businessActions } from '../../actions';
import AvailableLoanButton from '../../static/images/loans/available_loan-button.png';

import makeClasses from './styles';
import { Hidden } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';

const LoanAvailable = ({ i18n, loanType = 'Normal' }) => {
  const classes = makeClasses();
  const dispatch = useDispatch();

  const { user, loanAvailability } = useSelector(({ user, business }) => ({
    user: user.userData || {},
    loanAvailability: business.loanAvailability,
  }));

  const loansImages = {
    Normal: {
      desktop: require('../../static/images/loans/normal-available_loan.jpg').default,
      mobile: require('../../static/images/loans/normal-available_loan-mobile.jpg').default,
    },
    PremiumNormal: {
      desktop: require('../../static/images/loans/premium_normal-available_loan.jpg').default,
      mobile: require('../../static/images/loans/premium_normal-available_loan-mobile.jpg').default,
    },
    StoqueateNormal: {
      desktop: require('../../static/images/loans/stoquate_normal-available_loan.jpg').default,
      mobile: require('../../static/images/loans/stoquate_normal-available_loan-mobile.jpg').default,
    },
    Renewal: {
      desktop: require('../../static/images/loans/renewal-available_loan.jpg').default,
      mobile: require('../../static/images/loans/renewal-available_loan-mobile.jpg').default,
    },
    PremiumRenewal: {
      desktop: require('../../static/images/loans/premium_renewal-available_loan.jpg').default,
      mobile: require('../../static/images/loans/premium_renewal-available_loan-mobile.jpg').default,
    },
    StoqueateRenewal: {
      desktop: require('../../static/images/loans/stoqueate_renewal-available_loan.jpg').default,
      mobile: require('../../static/images/loans/stoqueate_renewal-available_loan-mobile.jpg').default,
    },
  };

  const handleOnClick = () => {
    dispatch(
      businessActions.getLoanLink((redirectLink) => {
        window.location.href = redirectLink;
      })
    );
  };

  return (
    <React.Fragment>
      <div className={clsx(classes.root, classes.banner)} onClick={handleOnClick}>
        <Hidden smDown>
          <img className={classes.image} src={loansImages[loanType]?.desktop} alt="loan" />
        </Hidden>
        <Hidden mdUp>
          <img className={classes.image} src={loansImages[loanType]?.mobile} alt="loan" />
        </Hidden>
        <div className={classes.bannerTextWrapper}>
          <div className={classes.bannerTitle}>
            {i18n.get(`Loans.BannerTitle${loanType}`).replace('{amount}', loanAvailability.availableAmount)}
          </div>
          <Markdown className={classes.bannerText}>
            {i18n
              .get(`Loans.BannerText${loanType}`)
              .replace('{companyName}', user.name)
              .replace('{amount}', loanAvailability.availableAmount)}
          </Markdown>
        </div>
        <img className={classes.bannerButton} src={AvailableLoanButton} alt="button" />
      </div>
    </React.Fragment>
  );
};

export default LoanAvailable;
