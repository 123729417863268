import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { goBack } from 'connected-react-router';

import ROUTES from '../../routes';
import { BoxSeparator, ContentBox, ForbiddenSection, Page, TitleBox } from '../../components';
import { Language, Utils, Colors } from '../../utils';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Hidden, withStyles } from '@material-ui/core';

import makeClasses from './styles';

import LoansLogo from '../../static/images/pym.svg';
import LoansSmallIcon from '../../static/images/pym_reduced.svg';
import { PAYMENT_STATUS } from '../Loans';
import { Policies } from '../../utils/Policies';

const StyledTableWhiteRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(0,0,0,0.03)',
    },
    '&:nth-of-type(even)': {
      backgroundColor: Colors.rgb.white,
    },
  },
}))(TableRow);

const LoanDetailsScreen = props => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const loanIndex = props.match.params.loanId || null;

  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, languageTexts, loan } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
    loan:
      Array.isArray(state.business.loans) && state.business.loans[loanIndex] ? state.business.loans[loanIndex] : null,
  }));
  const i18n = Language(languageTexts);

  useEffect(
    () => {
      window.scrollTo(0, 0);

      if (!policies || !policies.includes(Policies.types.LOANS)) {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  const handleOnGoBackClick = () => {
    dispatch(goBack());
  };

  const getPaymentStatus = paymentStatus => {
    if (paymentStatus === PAYMENT_STATUS.NOT_PAID) {
      return i18n.get('LoanDetails.InstallmentPaymentStatusNotPaid');
    } else if (paymentStatus === PAYMENT_STATUS.PARTIALLY_PAID) {
      return i18n.get('LoanDetails.InstallmentPaymentStatusParcialyPaid');
    } else if (paymentStatus === PAYMENT_STATUS.FULLY_PAID) {
      return i18n.get('LoanDetails.InstallmentPaymentStatusFullyPaid');
    }
    return '';
  };

  const renderLoanDetails = () => {
    return (
      <ContentBox title={i18n.get('LoanDetails.BoxItemTitle')} titleBold iconLeft={LoansSmallIcon}>
        <div className={classes.loansContentWrapper}>
          <div>
            <List className={classes.contentTile}>
              <ListItem className={classes.contentItem}>
                <ListItemText primary={i18n.get('Loans.DetailsRequestedDate')} className={classes.tileTitle} />
                <div className={classes.tileDetails}>
                  {moment(loan.requestedDate).format(i18n.get('DateFormat', false) || Utils.DEFAULT_DATE_FORMAT)}
                </div>
              </ListItem>
              <Hidden xsDown>
                <Divider />
              </Hidden>
              <ListItem className={classes.contentItem}>
                <ListItemText primary={i18n.get('Loans.DetailsRequestedPeriods')} className={classes.tileTitle} />
                <div className={classes.tileDetails}>{loan.requestedPeriods}</div>
              </ListItem>
            </List>
          </div>
          <div>
            <List className={classes.contentTile}>
              <ListItem className={classes.contentItem}>
                <ListItemText primary={i18n.get('Loans.DetailsRequestedCapital')} className={classes.tileTitle} />
                <div className={classes.tileDetails}>
                  {loan.currency} {Utils.formatCurrency(loan.requestedCapital)}
                </div>
              </ListItem>
              <Hidden xsDown>
                <Divider />
              </Hidden>
              <ListItem className={classes.contentItem}>
                <ListItemText primary={i18n.get('Loans.DetailsAnualDebtRate')} className={classes.tileTitle} />
                <div className={classes.tileDetails}>{loan.annualEffectiveRate}%</div>
              </ListItem>
            </List>
          </div>
        </div>
      </ContentBox>
    );
  };

  const renderCurrentInstallment = () => {
    const currentInstallment = loan.installments.find(installment => installment.number === loan.actualPeriod);

    return (
      <ContentBox title={i18n.get('LoanDetails.BoxItemInstallmentTitle')} titleBold>
        <div className={classes.loansContentWrapper}>
          <div>
            <List className={classes.contentTile}>
              <ListItem className={classes.contentItem}>
                <ListItemText primary={i18n.get('LoanDetails.InstallmentNumber')} className={classes.tileTitle} />
                <div className={classes.tileDetails}>{currentInstallment.number}</div>
              </ListItem>
              <Hidden xsDown>
                <Divider />
              </Hidden>
              <ListItem className={classes.contentItem}>
                <ListItemText
                  primary={i18n.get('LoanDetails.InstallmentPaymentStatus')}
                  className={classes.tileTitle}
                />
                <div className={classes.tileDetails}>{getPaymentStatus(currentInstallment.paymentStatus)}</div>
              </ListItem>
              <Hidden xsDown>
                <Divider />
              </Hidden>
              <ListItem className={classes.contentItem}>
                <ListItemText
                  primary={i18n.get('LoanDetails.InstallmentTotalAmountToPay')}
                  className={classes.tileTitle}
                />
                <div className={classes.tileDetails}>
                  {loan.currency} {Utils.formatCurrency(currentInstallment.totalAmountToPay)}
                </div>
              </ListItem>
            </List>
          </div>
          <div>
            <List className={classes.contentTile}>
              <ListItem className={classes.contentItem}>
                <ListItemText primary={i18n.get('LoanDetails.InstallmentDueDate')} className={classes.tileTitle} />
                <div className={classes.tileDetails}>
                  {moment(currentInstallment.dueDate).format(
                    i18n.get('DateFormat', false) || Utils.DEFAULT_DATE_FORMAT
                  )}
                </div>
              </ListItem>
              <Hidden xsDown>
                <Divider />
              </Hidden>
              <ListItem className={classes.contentItem}>
                <ListItemText primary={i18n.get('LoanDetails.InstallmentTotalAmount')} className={classes.tileTitle} />
                <div className={classes.tileDetails}>
                  {loan.currency} {Utils.formatCurrency(currentInstallment.totalAmount)}
                </div>
              </ListItem>
              <Hidden xsDown>
                <Divider />
              </Hidden>
              <ListItem className={classes.contentItem}>
                <ListItemText primary={i18n.get('LoanDetails.InstallmentPenalty')} className={classes.tileTitle} />
                <div className={classes.tileDetails}>
                  {loan.currency} {Utils.formatCurrency(currentInstallment.penalty)}
                </div>
              </ListItem>
            </List>
          </div>
        </div>
      </ContentBox>
    );
  };

  const renderTableRow = (installment, index) => {
    return (
      <StyledTableWhiteRow key={index}>
        <TableCell className={classes.tableCell}>{installment.number}</TableCell>
        <TableCell className={classes.tableCell}>
          {moment(installment.dueDate).format(i18n.get('DateFormat', false) || Utils.DEFAULT_DATE_FORMAT)}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {loan.currency} {Utils.formatCurrency(installment.totalAmount)}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {loan.currency} {Utils.formatCurrency(installment.totalAmountToPay)}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {loan.currency} {Utils.formatCurrency(installment.penalty)}
        </TableCell>
        <TableCell className={classes.tableCell}>{getPaymentStatus(installment.paymentStatus)}</TableCell>
      </StyledTableWhiteRow>
    );
  };

  const renderInstallments = () => {
    if (!loan) {
      return null;
    }

    const headers = [
      i18n.get('LoanDetails.InstallmentsTable.Headers.Number'),
      i18n.get('LoanDetails.InstallmentsTable.Headers.DueDate'),
      i18n.get('LoanDetails.InstallmentsTable.Headers.AmountToPay'),
      i18n.get('LoanDetails.InstallmentsTable.Headers.TotalAmountToPay'),
      i18n.get('LoanDetails.InstallmentsTable.Headers.Penalty'),
      i18n.get('LoanDetails.InstallmentsTable.Headers.PaymentStatus'),
    ];

    return (
      <ContentBox title={i18n.get('LoanDetails.InstallmentsTable.Title')} titleBold>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell className={classes.tableHeaderCell} key={index}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{loan.installments.map(renderTableRow)}</TableBody>
          </Table>
        </TableContainer>
      </ContentBox>
    );
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('LoanDetails.Title')} withActivePage={ROUTES.LOANS.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('LoanDetails.Title')} withActivePage={ROUTES.LOANS.id}>
      <TitleBox
        title={i18n.get('LoanDetails.BoxTitle')}
        iconRight={LoansLogo}
        back
        backTitle={i18n.get('LoanDetails.BoxBackButton')}
        onBackClick={handleOnGoBackClick}
        backButtonVariant="outlined"
      />
      <BoxSeparator />
      {renderLoanDetails()}
      <BoxSeparator size="small" />
      {renderCurrentInstallment()}
      <BoxSeparator size="small" />
      {renderInstallments()}
    </Page>
  );
};

LoanDetailsScreen.id = 'com.Handy.LoanDetails';

export default LoanDetailsScreen;
