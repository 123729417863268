import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ROUTES from '../../routes';
import { Box, BoxSeparator, ContentBox, ForbiddenSection, Page, TitleBox } from '../../components';
import { business as businessActions } from '../../actions';
import { Language } from '../../utils';
import HandyQr1 from '../../static/images/icons/icon_handyqr-1.jpg';
import HandyQr2 from '../../static/images/icons/icon_handyqr-2.jpg';
import HandyQr3 from '../../static/images/icons/icon_handyqr-3.jpg';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import SadIcon from '../../static/images/icons/sad.svg';
import makeClasses from './styles';
import QRModal from '../../components/QRModal';
import { Policies } from '../../utils/Policies';
import Markdown from 'markdown-to-jsx';

function HandyQr() {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [ handyLinkEnabled, setHandyLinkEnabled ] = useState(false);
  const [ qrCodeModalOpen, setQrCodeModalOpen ] = useState(false);
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const { policies, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(
    () => {
      if (policies && policies.includes(Policies.types.HANDY_QR)) {
        if (policies.includes(Policies.types.HANDY_QR_WRITE)) {
          dispatch(
            businessActions.getSecrets(false, handyLinkEnabled => {
              setHandyLinkEnabled(handyLinkEnabled);
            })
          );
        }
      } else {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  const _toggleQRCodeModal = () => {
    setQrCodeModalOpen(!qrCodeModalOpen);
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('HandyQr.Title')} withActivePage={ROUTES.HANDY_QR.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('HandyQr.Title')} withActivePage={ROUTES.HANDY_QR.id}>
      <TitleBox
        title={i18n.get('HandyQr.BoxTitle')}
        buttonRight={handyLinkEnabled}
        buttonRightText={isSmallScreen ? null : i18n.get('HandyQr.CreateNewQrButton')}
        buttonRightIcon={() => <AddIcon fontSize="small" />}
        buttonRightOnClick={_toggleQRCodeModal}
      />
      <BoxSeparator />
      <div className={!handyLinkEnabled ? classes.infoItemDisabled : null}>
        <Box>
          <div className={classes.infoItem}>
            <Grid container spacing={7}>
              <Grid item xs={12} sm={4} md={4} className={classes.infoItemWrapper}>
                <img src={HandyQr1} alt={i18n.get('HandyQr.InfoItem1Title')} className={classes.infoItemImage} />
                <div className={classes.infoItemTitle}>{i18n.get('HandyQr.InfoItem1Title')}</div>
                <div className={classes.infoItemText}>{i18n.get('HandyQr.InfoItem1Text')}</div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.infoItemWrapper}>
                <img src={HandyQr2} alt={i18n.get('HandyQr.InfoItem2Title')} className={classes.infoItemImage} />
                <div className={classes.infoItemTitle}>{i18n.get('HandyQr.InfoItem2Title')}</div>
                <div className={classes.infoItemText}>{i18n.get('HandyQr.InfoItem2Text')}</div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.infoItemWrapper}>
                <img src={HandyQr3} alt={i18n.get('HandyQr.InfoItem3Title')} className={classes.infoItemImage} />
                <div className={classes.infoItemTitle}>{i18n.get('HandyQr.InfoItem3Title')}</div>
                <div className={classes.infoItemText}>{i18n.get('HandyQr.InfoItem3Text')}</div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </div>
      <BoxSeparator />
      {!handyLinkEnabled ? (
        <ContentBox title={i18n.get('HandyQr.BoxHelpTitle')} titleBold iconRight={SadIcon} iconInline>
          <Markdown className={classes.legendItemWithBackgroundText}>{i18n.get('HandyQr.BoxHelpText')}</Markdown>
        </ContentBox>
      ) : null}
      <BoxSeparator />
      {qrCodeModalOpen ? (
        <QRModal
          title={i18n.get('HandyQr.QRModalTitle')}
          open={qrCodeModalOpen}
          onClose={_toggleQRCodeModal}
          showBranchesSelector
        />
      ) : null}
    </Page>
  );
}

HandyQr.id = 'com.Handy.HandyQr';

export default HandyQr;
