import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { push } from 'connected-react-router';
import ROUTES from '../../routes';
import {
  Page,
  OperationsTable,
  BranchesSelector,
  FiltersDateSelector,
  TitleBox,
  BoxSeparator,
  ContentBox,
  FiltersContainer,
  FiltersSeparator,
  EmptyMessage,
  ForbiddenSection,
} from '../../components';
import { business as businessActions } from '../../actions';
import { CUSTOM_FILTER, getDateRangeForFilter } from '../../components/FiltersDateSelector';
import { Language, Utils } from '../../utils';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { Policies } from '../../utils/Policies';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { toISOString } from '../../utils/Utils';

const HistoryScreen = ({ history }) => {
  const dispatch = useDispatch();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [ paymentHistory, setPaymentHistory ] = useState(null);
  const [ selectedBranch, setSelectedBranch ] = useState(searchParams.bid || 'all');
  const [selectedFilter, setSelectedFilter] = useState(searchParams.fid ? searchParams.fid === 'custom' ? searchParams.fid : parseInt(searchParams.fid) : 3);
  const [ selectedCustomFilter, setSelectedCustomFilter ] = useState(
    searchParams.fid === CUSTOM_FILTER ? { startDate: searchParams.fsd, endDate: searchParams.fed } : {}
  );
  const [ paginationPage, setPaginationPage ] = useState(searchParams.page ? parseInt(searchParams.page) : 1);
  const [ columnOrder, setColumnOrder ] = useState(searchParams.columnOrder ? parseInt(searchParams.columnOrder) : '');
  const [ columnOrderType, setColumnOrderType ] = useState(
    searchParams.columnOrderType ? parseInt(searchParams.columnOrderType) : ''
  );
  const [ forbiddenSection, setForbbidenSection ] = useState(false);
  const [ paginationPageSize ] = useState(10);
  const { policies, branches, languageTexts } = useSelector(state => ({
    policies: state.user.userData.policies || [],
    branches: state.business.branches || [],
    languageTexts: state.language.texts || {},
  }));
  const prevPaginationPage = useRef(null);
  const i18n = Language(languageTexts);
  const hasPaymentHistory = paymentHistory && paymentHistory.grid && paymentHistory.grid.rowsColumnAndSale.length > 0;
  const canExportHistory =
    policies && policies.includes(Policies.types.HISTORY) && policies.includes(Policies.types.SUPER_READ);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(
    () => {
      if (!policies || !policies.includes(Policies.types.HISTORY)) {
        setForbbidenSection(true);
      }
    },
    [ policies ]
  );

  useEffect(
    () => {
      if (!forbiddenSection) {
        if (paginationPage > 1 && prevPaginationPage.current === paginationPage) {
          _handleOnPaginationChange(1);
          prevPaginationPage.current = null;
        } else {
          loadData();
          prevPaginationPage.current = paginationPage;
        }
      }
    },
    [
      forbiddenSection,
      selectedBranch,
      selectedFilter,
      selectedCustomFilter,
      paginationPage,
      paginationPageSize,
      columnOrder,
      columnOrderType,
    ]
  );

  const loadData = () => {
    const branchId = selectedBranch !== 'all' ? selectedBranch : null;
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );
    const startDate = toISOString(dateFilter.startDate);
    const endDate = toISOString(dateFilter.endDate);

    dispatch(
      businessActions.getHistory(
        branchId,
        startDate,
        endDate,
        paginationPage,
        paginationPageSize,
        columnOrder,
        columnOrderType,
        (data) => {
          const headers = data?.grid?.columnsNameAndType ?? [];
          const body = data?.grid?.rowsColumnAndSale ?? [];

          const handyFlashHeader = headers.find(header => header.name === 'Handy Flash');
          if (handyFlashHeader) {
            handyFlashHeader.type = 'check';
            body.forEach(row => {
              const col = row.columns.find(column => column.columnNumber === handyFlashHeader.columnNumber);
              if (col) {
                col.data = col.data === 'INSTANT';
              }
            });
          }

          setPaymentHistory(data);
        }
      )
    );
  };

  const _goToOperationDetails = operationId => e => {
    e.preventDefault();

    dispatch(push(`/operacion/${operationId}`));
  };

  const _handleOnBranchSelected = branch => {
    setSelectedBranch(branch);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.bid = branch;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnFilterSelected = (filter, startDate, endDate) => {
    setSelectedFilter(filter);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.fid = filter;

    if (filter === CUSTOM_FILTER) {
      setSelectedCustomFilter({ startDate, endDate });
      searchParams.fsd = startDate;
      searchParams.fed = endDate;
    }

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnPaginationChange = nextPage => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableSortChange = (columnNumber, sortType) => {
    setColumnOrder(columnNumber);
    setColumnOrderType(sortType);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.columnNumber = columnNumber;
    searchParams.sortType = sortType;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const _handleOnTableExport = () => {
    const branchId = selectedBranch !== 'all' ? selectedBranch : null;
    const dateFilter = getDateRangeForFilter(
      selectedFilter,
      selectedFilter === CUSTOM_FILTER ? selectedCustomFilter : null
    );

    dispatch(
      businessActions.exportTable(
        'history',
        {
          traceId: branchId,
          start: toISOString(dateFilter.startDate),
          end: toISOString(dateFilter.endDate),
          columnOrder,
          columnOrderType,
        },
        file =>
          Utils.downloadFile(
            file,
            'TransferenciasBancarias_' +
              dateFilter.startDate.format(i18n.get('ExportDateFormat')) +
              '_' +
              dateFilter.endDate.format(i18n.get('ExportDateFormat')) +
              '.xlsx'
          )
      )
    );
  };

  const renderBranchesSelector = () => (
    <BranchesSelector branches={branches} selectedBranch={selectedBranch} onBranchSelected={_handleOnBranchSelected} />
  );

  const renderFiltersDateSelector = () => (
    <FiltersDateSelector
      selectedFilter={selectedFilter}
      onFilterSelected={_handleOnFilterSelected}
      align="right"
      dateFormat={i18n.get('DateFormat', false)}
    />
  );

  const renderPaymentHistory = () => {
    if (!hasPaymentHistory) {
      return null;
    }

    const headers = paymentHistory.grid.columnsNameAndType;
    const body = paymentHistory.grid.rowsColumnAndSale;

    return (
      <OperationsTable
        headers={headers}
        body={body}
        paginationSimple
        currentPage={paginationPage}
        hasMorePages={body.length === paginationPageSize}
        onClick={_goToOperationDetails}
        onPaginationChange={_handleOnPaginationChange}
        showDetails={false}
        onTableSortChange={_handleOnTableSortChange}
        sm={isSmallScreen}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page withHeader withSidebar withHeaderTitle={i18n.get('History.Title')} withActivePage={ROUTES.HISTORY.id}>
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page withHeader withSidebar withHeaderTitle={i18n.get('History.Title')} withActivePage={ROUTES.HISTORY.id}>
      <TitleBox title={i18n.get('History.BoxTitle')} />
      <BoxSeparator />
      <ContentBox title={i18n.get('History.BoxFiltersTitle')} titleBold>
        <FiltersContainer>
          {renderBranchesSelector()}
          <FiltersSeparator />
          {renderFiltersDateSelector()}
        </FiltersContainer>
      </ContentBox>
      <BoxSeparator size="small" />
      <ContentBox
        title={i18n.get('History.GridTitle')}
        titleBold
        button={hasPaymentHistory && canExportHistory ? paymentHistory.grid.exportable : false}
        buttonText={i18n.get('History.GridExportButton')}
        buttonOnClick={_handleOnTableExport}
        buttonRightIcon={() => <SaveAltIcon fontSize="small" />}
      >
        {hasPaymentHistory ? (
          renderPaymentHistory()
        ) : (
          <EmptyMessage textOnly>{i18n.get('History.GridEmptyMessage')}</EmptyMessage>
        )}
      </ContentBox>
    </Page>
  );
};

HistoryScreen.id = 'com.Handy.History';

export default HistoryScreen;
